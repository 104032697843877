import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import SemiFinishedProductWarehouseSidebar from './SemiFinishedProductWarehouseSidebar';
import SemiFinishedProductWarehouseTable from './SemiFinishedProductWarehouseTable';

const { Content } = Layout;

const SemiFinishedProductWarehouse = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  useEffect(()=>{
    console.log(filters);
  }, [filters])
  return (
    <Layout>
      <SemiFinishedProductWarehouseSidebar onFilter={handleFilter}/>
      <Layout>
        <Content className="site-layout-content">
          <SemiFinishedProductWarehouseTable filters={filters} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default SemiFinishedProductWarehouse;
