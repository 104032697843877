import { Card } from "antd";
import { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import dayjs from "dayjs";
import errorEquipmentLog from "../../../../../api/errorEquipmentLog";
const ErrorFrequencyChart = ({ filters }) => {
    const [data, setData] = useState([]);
    const [loadingChart, setLoadingChart] = useState(false);
    const fetchData = async (filters) => {
        try {
            setLoadingChart(true);
            if (filters?.start_time) filters.start_time = dayjs(filters.start_time).format('YYYY-MM-DD');
            if (filters?.stop_time) filters.stop_time = dayjs(filters.stop_time).format('YYYY-MM-DD');
            const response = await errorEquipmentLog.equipmentErrorFrequency(filters);
            // const response = {data:{'ERROR01': 10, 'ERROR02': 20, 'ERROR 3': 25}};
            const pie = [];
            Object.keys(response.data).map(key => {
                pie.push({ name: key, y: response.data[key] })
            });
            if (pie.length === 0) {
                pie.push({name: 'Không có lỗi', y: 0});
            }
            setData(pie);
            setLoadingChart(false);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchData(filters);
    }, [filters]);
    useEffect(() => {
        if (data.length === 0) {
            return;
        }
        const options = {
            chart: {
                type: 'pie',
                height: 190,
            },
            title: false,
            tooltip: {
                valueSuffix: ''
            },
            plotOptions: {
                series: {
                    allowPointSelect: false,
                    cursor: 'pointer',
                    dataLabels: [{
                        enabled: true,
                        distance: 9
                    }, {
                        enabled: true,
                        distance: -40,
                        format: '{point.percentage:.1f}%',
                        style: {
                            fontSize: '12px',
                            opacity: 0.7
                        },
                        filter: {
                            operator: '>',
                            property: 'percentage',
                            value: 10
                        }
                    }]
                }
            },
            series: [
                {
                    name: 'Lần xuất hiện',
                    colorByPoint: true,
                    data: data
                }
            ],
            exporting: false,
            credits: { enabled: false }
        };

        Highcharts.chart("error-frequency-chart", options);
    }, [data]);

    return <Card title="Biều đồ tỉ lệ lỗi dừng máy"
        loading={loadingChart}
        styles={{body: {padding: '0px'}}}
        style={
            { padding: '0px' }
        }>
        <div id="error-frequency-chart" />
    </Card>
};

export default ErrorFrequencyChart;