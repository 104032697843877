import React, { useContext, useEffect, useState } from 'react';
import { Layout } from 'antd';
import { HomeOutlined, StarOutlined, ToolOutlined, AppstoreOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import './OIFooter.css';
import AuthContext from '../../../context/AuthContext';

const { Footer } = Layout;

const OIFooter = () => {
  const { user } = useContext(AuthContext);
  const [permissions, setPermissions] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    { key: '/oi/production', icon: <HomeOutlined />, label: 'Sản xuất', auth: 'oi.production' },
    { key: '/oi/quality-dd', icon: <StarOutlined />, label: 'Đầu đơn', auth: 'oi.quality_dd' },
    { key: '/oi/quality-iqc', icon: <StarOutlined />, label: 'IQC', auth: 'oi.quality_iqc' },
    { key: '/oi/quality-pqc', icon: <StarOutlined />, label: 'PQC', auth: 'oi.quality_pqc' },
    { key: '/oi/quality-oqc', icon: <StarOutlined />, label: 'OQC', auth: 'oi.quality_oqc' },
    { key: '/oi/equipment', icon: <ToolOutlined />, label: 'Thiết bị', auth: 'oi.equipment' },
    { key: '/oi/material-warehouse-import', icon: <AppstoreOutlined />, label: 'Kho NVL', auth: 'oi.material_warehouse_import' },
    { key: '/oi/semi-finished-product-warehouse', icon: <AppstoreOutlined />, label: 'Kho BTP', auth: 'oi.semi_finished_product_warehouse' },
    { key: '/oi/finished-product-warehouse', icon: <AppstoreOutlined />, label: 'Kho TP', auth: 'oi.finished_product_warehouse' },
  ];

  const handleTabClick = (key) => {
    navigate(key);
  };

  useEffect(() => {
    setPermissions([...(user?.role.permissions?.map(({ slug }) => slug) || [])]);
  }, []);

  return (
    <Footer className="oi-footer">
      {tabs.filter(({auth}) => !auth || permissions.includes(auth)).map((tab) => (
        <div
          key={tab.key}
          className={`oi-footer-tab ${location.pathname === tab.key ? 'active' : ''}`}
          onClick={() => handleTabClick(tab.key)}
        >
          {tab.icon}
          <div>{tab.label}</div>
        </div>
      ))}
    </Footer>
  );
};

export default OIFooter;
