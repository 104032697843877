import React, { useState, useEffect } from 'react';
import { Table, Button, Space, Form, Card, Upload, message, Popconfirm, Input, Select, InputNumber, Modal, DatePicker, Col, Row, Divider, Descriptions, Dropdown, Menu, Alert, Spin } from 'antd';
import { EditOutlined, DeleteOutlined, UploadOutlined, PlusOutlined, CloseOutlined, EyeOutlined, DownOutlined } from '@ant-design/icons';
import materialExportApi from '../../../api/materialExportApi';
import config from '../../../config/config';
import materialManagementApi from '../../../api/materialManagementApi';
import unitManagementApi from '../../../api/unitManagementApi';
import dayjs from 'dayjs';
import palletManagementApi from '../../../api/palletManagementApi';
import { getProductionPlans } from '../../../api/productionPlanApi';
import axiosInstance from '../../../api/axiosInstance';
import { numberFormat } from '../../../utils/numberFormat';
import CalculateTableHeight from '../../../components/calculateTableHeight';
const { RangePicker } = DatePicker;

const MaterialExportTable = ({ filters }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isProductionPlanModalVisible, setIsProductionPlanModalVisible] = useState(false);
    const [isViewModalVisible, setIsViewModalVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [form] = Form.useForm();
    const [editingRecord, setEditingRecord] = useState(null);
    const [data, setData] = useState([]);
    const [materialOptions, setMaterialOptions] = useState([]);
    const [palletOptions, setPalletOptions] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 20,
        total: 0,
    });
    const [selectedRowKey, setSelectedRowKey] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [dataProductionPlan, setDataProductionPlan] = useState([]);
    const [rangePicker, setRangePicker] = useState(null);
    const [bomSelected, setBomSelected] = useState(null);
    const [loadingBom, setLoadingBom] = useState(false);
    const [planLatest, setPlanLatest] = useState([]);

    useEffect(() => {
        CalculateTableHeight("table-material");
        fetchMaterial();
        // fetchPallet();
        fetchUnit();
        fetchData({ ...filters, page: pagination.page, pageSize: pagination.pageSize });
        fetchPlanLatest();
    }, [filters]);

    const fetchData = async (params = {}) => {
        setLoading(true);
        try {
            if (params?.export_date) params.export_date = dayjs(params.export_date).format('YYYY-MM-DD');
            params.withs = 'history';
            const response = await materialExportApi.getMaterialExports(params);
            console.log(response);
            if (response.success) {
                setData(response.data.data);
                setPagination({
                    ...pagination,
                    total: response.data.pagination.total,
                });
            }
        } catch (error) {
            message.error('Failed to fetch data');
        }
        setLoading(false);
    };

    const fetchPlanLatest = async () => {
        const response = await axiosInstance.get(config.apiUrl + '/production-plans-for-warehouse-export');
        if (response.success) {
            setPlanLatest(response.data || []);
        }
    }

    const fetchPallet = async () => {
        try {
            const response = await palletManagementApi.getPallets();
            await setPalletOptions((response.data.data ?? []).map((item) => {
                return {
                    ...item,
                    label: <span>
                        <span style={{ fontWeight: 600 }}>{item?.material?.material_name}</span><small style={{ color: 'gray' }}> - ({item?.id})</small>
                    </span>,
                    value: `${item.id}|${item?.material?.id}`
                };
            }));
            if ((response.data.data ?? []).length === 0) message.info('Dữ liệu NVL trống!')
        } catch (error) {
            message.error('Failed to fetch materials');
        }
    }

    const fetchUnit = async () => {
        try {
            const response = await unitManagementApi.getUnits();
            await setUnitOptions((response.data.data ?? []).map((item) => {
                return { label: item?.unit_name, value: String(item?.id) };
            }));
            if ((response.data.data ?? []).length === 0) message.info('Dữ liệu ĐVT trống!')
        } catch (error) {
            message.error('Failed to fetch units');
        }
    }

    const fetchMaterial = async (params = null) => {
        try {
            const response = await materialManagementApi.getMaterials(params);
            await setMaterialOptions((response.data.data ?? []).map((item) => {
                return { label: <>{item?.id}<small style={{ color: 'gray' }}>({item?.material_name})</small></>, value: item?.id };
            }));
            if ((response.data.data ?? []).length === 0) message.info('Dữ liệu NVL trống!')
        } catch (error) {
            message.error('Failed to fetch materials');
        }
    }

    const showModal = async () => {
        setLoading(true);
        setIsEditMode(false);
        setEditingRecord(null);
        form.setFieldsValue({
            export_date: dayjs(),
            materialItems: [{ item_id: null, quantity: 1 }],
        });
        setIsModalVisible(true);
        setLoading(false);
    };

    const showProductionPlanModal = async () => {
        setLoading(true);
        setSelectedRow(null);
        setBomSelected(null);
        setSelectedRowKey(null);
        const response = await getProductionPlans(filters);
        if (response?.success) {
            setDataProductionPlan(response.data?.data ?? []);
        }
        setIsProductionPlanModalVisible(true);
        setLoading(false);
    };

    const showEditModal = async (record) => {
        setLoading(true);
        const rows = await record?.items?.map((row) => {
            return { item_id: `${row.pallet_id}|${row.material_id}`, quantity: row.quantity };
        });
        setIsEditMode(true);
        setEditingRecord(record);
        form.setFieldsValue({
            materialItems: rows,
            note: record.note ?? null
        });
        setIsModalVisible(true);
        setLoading(false);
    };

    const viewModal = async (record) => {
        setLoading(true);
        setEditingRecord(record);
        setIsViewModalVisible(true);
        setLoading(false);
    };

    const handleOk = async () => {
        form.validateFields().then(async (values) => {
            try {
                const materialItems = values.materialItems?.map((item) => {
                    const { item_id, quantity } = item;
                    // const split = String(item_id).split('|');
                    // const pallet_id = split[0];
                    const material_id = item_id;
                    return { material_id, quantity };
                })
                if (isEditMode) {
                    const response = await materialExportApi.updateMaterialExport(
                        editingRecord?.id,
                        {
                            material_items: materialItems,
                        }
                    );
                    if (response.success) await fetchData(pagination);
                } else {
                    const response = await materialExportApi.createMaterialExport({
                        note: values?.note ?? null,
                        material_items: materialItems,
                    });
                    if (response.success) {
                        setPagination({ ...pagination, total: pagination.total + 1 });
                        await fetchData(pagination);
                    }
                }
                setIsModalVisible(false);
                form.resetFields();
            } catch (error) {
                message.error('Failed to save');
            }
        }).catch((error) => { console.log(error) });
    };

    const handleDelete = async (id) => {
        try {
            const result = await materialExportApi.deleteMaterialExport(id);
            if (result.success) await fetchData(pagination);
        } catch (error) {
            message.error('Failed to delete');
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
    };

    const handleUploadChange = (info) => {
        try {
            if (info.file.status === 'uploading') {
                setUploading(true);
            } else {
                if (info.file.status === 'done') {
                    if (!info.file?.response?.success)
                        throw new Error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`)
                    fetchData({ ...filters, ...pagination });
                    message.success(`${info.file.name} - Upload thành công`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} - Upload thất bại`);
                }
            }
        } catch (error) {
            message.error(error.message)
        } finally {
            setUploading(false);
        }
    };

    const uploadProps = {
        name: 'file',
        accept: '.xlsx, .xls',
        action: config.apiUrl + '/import-material-exports', // Thay đổi endpoint theo API của bạn
        onChange: handleUploadChange,
        showUploadList: false,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    };

    const columns = [
        { title: 'STT', dataIndex: 'no', key: 'no', align: 'center', render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1, width: 70 },
        { title: 'Mã phiếu xuất', dataIndex: 'id', key: 'id', align: 'center', width: 120 },
        { title: 'Đơn sản xuất', dataIndex: 'batch_id', key: 'batch_id', align: 'center', width: 120 },
        { title: 'Ngày xuất', dataIndex: 'export_date', key: 'export_date', align: 'center', width: 90 },
        { title: 'Người tạo', dataIndex: 'created_by', key: 'created_by', align: 'center', width: 100, render: (_, record) => record?.user?.name },
        {
            title: 'Hành động', key: 'action', width: 100, render: (text, record) => (
                <Space size="middle">
                    <Button size='small' icon={<EyeOutlined />} onClick={() => viewModal(record)} />
                    <Button type={(record?.history ?? []).length > 0 ? undefined : 'primary'} size='small' icon={<EditOutlined />} onClick={() => showEditModal(record)} disabled={(record?.history ?? []).length > 0} />
                    <Popconfirm title="Bạn có chắc chắn muốn xóa?" onConfirm={() => handleDelete(record.id)} okText="Có" cancelText="Không" disabled={(record?.history ?? []).length > 0}>
                        <Button type='primary' size='small' icon={<DeleteOutlined />} danger disabled={(record?.history ?? []).length > 0} />
                    </Popconfirm>
                </Space>
            ), align: 'center'
        }
    ];

    const columnsViewTable = [
        { title: 'STT', dataIndex: 'no', key: 'no', width: 50, align: 'center', render: (_, record, index) => index + 1 },
        { title: 'Nguyên vật liệu', dataIndex: 'material_id', key: 'material_id', align: 'center', render: (value) => materialOptions.find((item) => item.value === value)?.label },
        { title: 'Số lượng', dataIndex: 'quantity', key: 'quantity', align: 'center', render: (value) => numberFormat(value) },
        { title: 'Đơn vị tính', dataIndex: 'unit_id', key: 'unit_id', align: 'center', render: (value) => unitOptions.find((item) => String(item.value) === String(value))?.label }
        // { title: 'Ghi chú', dataIndex: 'note', key: 'note', align: 'center' }
    ];

    const itemsDescription = [
        { key: 'id', label: 'Mã phiếu xuất', children: editingRecord?.id },
        { key: 'batch_id', label: 'Đơn sản xuất', children: editingRecord?.batch_id },
        { key: 'export_date', label: 'Ngày xuất', children: editingRecord?.export_date },
        { key: 'user', label: 'Người tạo', children: editingRecord?.user?.name },
        { key: 'note', label: 'Ghi chú', columns: 3, children: editingRecord?.note ?? null }
    ];

    const columnsProductionPlan = [
        { title: 'STT', dataIndex: 'stt', width: '50px', render: (_, item, index) => index + 1 },
        { title: 'Số đơn sản xuất', dataIndex: 'batch_id', width: '120px' },
        { title: 'Ngày sản xuất', dataIndex: 'start_time', render: (_, item) => dayjs(item.start_time).format('DD/MM/YYYY HH:mm') ?? "", width: '140px' },
        { title: 'Ngày kết thúc', dataIndex: 'end_time', render: (_, item) => dayjs(item.end_time).format('DD/MM/YYYY HH:mm') ?? "", width: '140px' },
        { title: 'Máy', dataIndex: 'equipment_name', render: (_, item) => item.equipment_id ?? "", width: '100px' },
        { title: 'Loại công suất (Kg/h)', dataIndex: 'capacity', width: '180px' },
        { title: 'Thời gian sản xuất (giờ)', dataIndex: 'production_time', width: '180px' },
        { title: 'Số PI', dataIndex: 'pi', width: '80px' },
        { title: 'Mã nội bộ', dataIndex: 'product_id', width: '100px' },
        { title: 'Mã khách hàng', dataIndex: 'customer_id', width: '120px' },
        { title: 'Mã lô', dataIndex: 'batch_code', width: '90px' },
        { title: 'Khối lượng', dataIndex: 'quantity', width: '90px', render: (value) => numberFormat(value) },
        { title: 'Số mẻ', dataIndex: 'batch_number', width: '70px' },
        { title: 'Khối lượng/mẻ', dataIndex: 'volume_of_batch', width: '120px' },
        { title: 'Loại bao bì', dataIndex: 'packaging_type', width: '300px' },
        { title: 'Lot Pallet', dataIndex: 'lot_pallet', width: '200px' },
        { title: 'Khối lượng bao', dataIndex: 'bag_stacking', width: '120px' },
        { title: 'Xếp Pallet', dataIndex: 'pallet_stacking', width: '100px' },
        { title: 'Màng co, shipping', dataIndex: 'shrink_wrap', width: '200px' },
    ];

    const items = [
        { label: <><PlusOutlined /> Tạo lệnh xuất</>, key: 'export' },
        { label: <><PlusOutlined /> Tạo lệnh xuất từ KHSX</>, key: 'production-plan' }
    ];


    const onSelectChange = async (record, selected) => {
        if (selected) {
            const { pi, customer_id } = record
            if (pi && customer_id) {
                setLoadingBom(true);
                const resBom = await axiosInstance.get('/boms', { params: { pi, customer_id } })
                if (resBom.data?.data?.length > 0) {
                    setBomSelected(resBom.data?.data[0] ?? null);
                } else setBomSelected(null);
                setTimeout(() => {
                    setLoadingBom(false);
                }, 500);
            } else {
                setBomSelected(null);
            }
            setSelectedRowKey(record.key);
            setSelectedRow(record);
        } else {
            setBomSelected(null);
            setSelectedRowKey(null);
            setSelectedRow(null);
        }
    };

    const rowProductionPlanSelection = {
        type: 'radio',
        selectedRowKeys: [selectedRowKey],
        onSelect: onSelectChange,
    };

    const onClickItem = ({ key }) => {
        switch (key) {
            case 'export':
                showModal();
                break;
            case 'production-plan':
                showProductionPlanModal();
                break;
            default:
                message.error('Key invalid!');
                break;
        }
    }

    const rowClassName = (record) => {
        switch (record.status) {
            case 2:
                return 'table-row-grey';
            case 1:
                return 'table-row-green';
            default:
                return "";
        }
    }

    const handleCreateExportFromProductionPlan = async () => {
        if (selectedRow) {
            const response = await axiosInstance.post('material-exports-with-plan', { id: selectedRow?.id });
            if (response?.success) {
                await fetchData(pagination);
                setIsProductionPlanModalVisible(false);
            }
        } else message.error('VUI LÒNG CHỌN KẾ HOẠCH SẢN XUẤT');
    }

    const handleChangeDate = async (dates) => {
        setRangePicker(dates);
        if (dates) {
            filters.start_date = dayjs(dates[0]).format('YYYY-MM-DD');
            filters.end_date = dayjs(dates[1]).format('YYYY-MM-DD');
        } else {
            filters.start_date = null
            filters.end_date = null
        }
        setLoading(true);
        const response = await getProductionPlans(filters);
        if (response?.success) {
            setDataProductionPlan(response.data?.data ?? []);
        }
        setLoading(false);
    }

    return (
        <Card
            style={{height: "100%"}}
            title="Quản lý phiếu xuất kho NVL"
            extra={
                <div className="table-actions">
                    {planLatest.length > 0 && (<Alert style={{height: '32px'}} message={`Kế hoạch mới upload: ${planLatest.map(({batch_id}) => batch_id).join(', ')}`} />)}
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />} type="primary" loading={uploading} style={{ margin: '0 8px' }}>
                            {uploading ? 'Đang tải lên' : 'Upload Excel'}
                        </Button>
                    </Upload>
                    <div>
                        <Dropdown
                            menu={{
                                items,
                                onClick: onClickItem
                            }}
                        >
                            <Button type="primary" onClick={(e) => e.preventDefault()}>
                                <Space>
                                    Tạo lệnh xuất
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    </div>
                </div>
            }
        >
            <Table
                className="table-material"
                loading={loading}
                // rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
                pagination={{
                    current: pagination.page,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    onChange: (page, pageSize) => {
                        setPagination({ ...pagination, page, pageSize });
                        fetchData({ ...filters, page, pageSize });
                    },
                }}
                bordered
                size="small"
                scroll={{x: 700, y: CalculateTableHeight("table-material")}}
            />

            <Modal
                title={
                    <div className="modal-title">
                        {isEditMode ? "Sửa phiếu xuất kho" : "Tạo phiếu xuất kho"}
                        <div className="modal-title-divider"></div>
                    </div>
                }
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Lưu lại"
                cancelText="Thoát"
                width={800}
                loading={loading}
            >
                <Form form={form} layout="vertical" name="form_in_modal">
                    <Form.Item name="note" label="Ghi chú" rules={[{ max: 255 }]}>
                        <Input placeholder="Nhập ghi chú" />
                    </Form.Item>
                    <Divider />
                    <Form.List name="materialItems">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Row gutter={8} key={key}>
                                        <Col span={11}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'item_id']}
                                                label={key === 0 ? "Mã nguyên vật liệu" : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng nhập mã NVL!',
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Mã nguyên vật liệu" options={materialOptions.map(e => (form.getFieldValue('materialItems') ?? []).some(o => o?.item_id === e.value) ? ({ ...e, disabled: true }) : e)} showSearch optionFilterProp='value' onSelect={(val, opt) => {
                                                    form.setFieldValue('materialItems', (form.getFieldValue('materialItems') ?? []).map((e, i) => {
                                                        if (i === key) {
                                                            // return { ...e, quantity: opt.quantity }
                                                            return { ...e, quantity: 1 }
                                                        }
                                                        return e;
                                                    }))
                                                }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={11}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'quantity']}
                                                label={key === 0 ? "Số lượng (kg)" : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng nhập số lượng!',
                                                    },
                                                ]}
                                            >
                                                <InputNumber placeholder="Nhập số lượng" min={0} style={{ width: '100%' }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item label={key === 0 ? " " : ""}>
                                                <Button block icon={<CloseOutlined />} disabled={key === 0} onClick={() => remove(name)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Thêm nguyên vật liệu
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Modal>

            {/* View modal */}
            <Modal
                title={
                    <div className="modal-title">
                        <span>Thông tin phiếu xuất</span>
                        <div className="modal-title-divider"></div>
                    </div>
                }
                open={isViewModalVisible}
                onCancel={() => setIsViewModalVisible(false)}
                okButtonProps={{ style: { display: 'none' } }}
                cancelText="Thoát"
                width={800}
                loading={loading}
            >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <Descriptions items={itemsDescription} layout="vertical" bordered />
                    <Table dataSource={editingRecord?.items} columns={columnsViewTable} bordered pagination={false} />
                </Space>
            </Modal>

            {/* KHSX */}
            <Modal
                title={
                    <div className="modal-title">
                        <span>Tạo phiếu xuất từ KHSX</span>
                        <div className="modal-title-divider"></div>
                    </div>
                }
                open={isProductionPlanModalVisible}
                onOk={handleCreateExportFromProductionPlan}
                onCancel={() => setIsProductionPlanModalVisible(false)}
                okText="Xác nhận"
                cancelText="Thoát"
                width={"90%"}
                loading={loading}
            >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <Row gutter={5}>
                        <Col span={12}>
                            <RangePicker defaultValue={rangePicker} format="DD-MM-YYYY" placeholder={["Ngày bắt đầu", "Ngày kết thúc"]} style={{ width: '50%' }} onChange={handleChangeDate} />
                        </Col>
                        <Col span={12}>
                            <Spin spinning={loadingBom}>
                                {bomSelected ? (<Alert message={`ID BOM: ${bomSelected?.id}`} type="info" />) : (<>
                                    {selectedRow && (<Alert message="KHÔNG TÌM THẤY BOM" type="warning" />)}
                                </>)}
                            </Spin>
                        </Col>
                    </Row>

                    <Table
                        loading={loading}
                        locale={{ emptyText: 'Không có dữ liệu' }}
                        dataSource={dataProductionPlan.map(e => ({ ...e, key: e.id }))}
                        rowSelection={rowProductionPlanSelection}
                        columns={columnsProductionPlan.map(e => ({ ...e, align: 'center' }))}
                        pagination={false}
                        scroll={{ x: '2200px', y: 'calc(100vh - 400px)' }}
                        size='small'
                        bordered
                        rowClassName={rowClassName}
                        onRow={(record) => ({
                            onClick: () => {
                                onSelectChange(record, true);
                            },
                        })}
                    />
                </Space>
            </Modal>
        </Card>
    );
};

export default MaterialExportTable;
