// src/pages/UI/Manufacturing/ManufacturingSidebar.js
import React, { useEffect, useState } from 'react';
import { Layout, Tree, DatePicker, Button, Form, Divider, Select, ConfigProvider, Radio, Input } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import UISidebar from '../../components/Sidebar';

dayjs.locale('vi');

const EquipmentPerformanceSidebar = ({ onFilter, filters }) => {
  const [formFields, setFormFields] = useState([
    {
      type: 'DatePicker',
      label: 'Thời gian dừng máy',
      name: 'stop_time',
      placeholder: 'Ngày bắt đầu',
      className: 'mb-1',
      value: filters.stop_time,
    },
    {
      type: 'DatePicker',
      name: 'start_time',
      placeholder: 'Ngày kết thúc',
      value: filters.start_time,
    },
    {
      type: 'Input',
      label: 'Mã thiết bị',
      name: 'equipment_id',
      placeholder: 'Nhập mã thiết bị',
    },
    {
      type: 'Input',
      label: 'Mã lỗi thiết bị',
      name: 'error_equipment_id',
      placeholder: 'Nhập mã lỗi thiết bị',
    },
    {
      type: 'Input',
      label: 'Người xử lý',
      name: 'handler_id',
      placeholder: 'Nhập tên người xử lý',
    },
    // Add other fields here
  ]);

  return (
    <UISidebar formFields={formFields} onFilter={onFilter} />
  );
};

export default EquipmentPerformanceSidebar;
