import { LineOutlined } from '@ant-design/icons';
import { Area, Column } from '@ant-design/plots';
import { Card, Space } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import Highcharts from "highcharts";
function generateFakeData(startDate, days) {
    function randomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function formatDate(date) {
        return date.format('DD-MM');
    }

    const data = [];
    const start = startDate?.subtract(days, 'day');

    for (let i = 0; i <= days; i++) {
        const currentDate = start.add(i, 'day');
        const entry = {
            date: formatDate(currentDate),
            previousYear: randomInt(50, 100),
            currentYear: randomInt(50, 100),
        };

        data.push(entry);
    }

    return data;
}
const fakeData = generateFakeData(dayjs(), 6);
const ThirdChart = ({ data }) => {
    // const [data, setData] = useState([]);
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             setData(fakeData);
    //         } catch (error) {
    //             console.error("Error fetching data: ", error);
    //         }
    //     };
    //     fetchData();
    // }, []);
    useEffect(() => {
        if (data.length === 0) {
            return;
        }
        const dates = [...new Set(data.map(item => item.date))];
        const previousYear = data.map(item => item.previousYear);
        const currentYear = data.map(item => Number(item.energy?.toFixed(2)));
        const options = {
            chart: {
                type: 'line',
                height: 350,
            },
            title: {
                text: 'Biểu đồ điện năng tiêu thụ'
            },
            xAxis: {
                categories: dates
            },
            yAxis: {
                title: {
                    text: 'kWh'
                },
            },
            tooltip: {
                shared: true,
                useHTML: true,
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr>' +
                    '<td style="padding:0"><b>{point.y}</b> kWh</td></tr>',
                footerFormat: '</table>'
            },
            plotOptions: {
                spline: {
                    marker: {
                        radius: 4,
                        lineColor: '#666666',
                        lineWidth: 1
                    }
                }
            },
            series: [
                // {
                //     name: 'Năm trước',
                //     data: previousYear
                // },
                {
                    name: 'Năm nay',
                    data: currentYear
                }
            ],
            exporting: false,
            credits: { enabled: false }
        };

        Highcharts.chart("power-consume-chart", options);
    }, [data]);


    return (
        <Card className="kpi-chart" title="">
            <div id='power-consume-chart' />
        </Card>
    );
};

export default ThirdChart;
