// src/pages/UI/Manufacturing/ManufacturingHistory.js
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import EquipmentPerformanceSidebar from './EquipmentPerformanceSidebar';
import EquipmentPerformanceTable from './EquipmentPerformanceTable';
import EquipmentPerformanceCharts from './EquipmentPerformanceCharts';
import dayjs from 'dayjs';

const { Content } = Layout;

const EquipmentPerformance = () => {
  const [filters, setFilters] = useState({stop_time: dayjs().subtract(10, 'day'), start_time: dayjs()});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  return (
    <Layout>
      <EquipmentPerformanceSidebar onFilter={handleFilter} filters={filters} />
      <Layout>
        <Content className="site-layout-content">
          <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 8 }}>
            <EquipmentPerformanceCharts filters={filters}/>
            <EquipmentPerformanceTable filters={filters}/>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default EquipmentPerformance;
