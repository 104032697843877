import { QrcodeOutlined } from '@ant-design/icons';
import { Button, Col, Form, InputNumber, Layout, Modal, Row, Select, Space, Table, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import warehouseEntryManagementApi from '../../../../api/warehouseEntryManagementApi';
import Scanner from '../../../../components/Scanner';
import lotApi from '../../../../api/lotApi';
import warehouseLocationManagementApi from '../../../../api/warehouseLocationManagementApi';
import { summaryWarehouseHistory } from '../../../../api/warehouseHistoryApi';
import axiosInstance from '../../../../api/axiosInstance';
import { numberFormat } from '../../../../utils/numberFormat';
import { tableHeight } from '../../components/OICalculateTableHeight';
import config from '../../../../config/config';

const { Content } = Layout;
const { Option } = Select;

const OISemiFinishedProductWarehouse = () => {
    const navigate = useNavigate();
    const context = useOutletContext();
    const [form] = Form.useForm();
    const [selectedProcess, setSelectedProcess] = useState(1);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataLot, setDataLot] = useState([]);
    const [dataLotScanner, setDataLotScanner] = useState({ // fields in QR
        warehouse_location_id: null,
        lot_id: null,
        quantity: 0,
        unit_id: null,
        unit_name: null,
    });
    const [tableDataLotScanner, setTableDataLotScanner] = useState([]);
    const [summary, setSummary] = useState({ import: 0, export: 0 });
    const [scannerKey, setScannerKey] = useState(Date.now());
    const isScanningRef = useRef(true);
    const isScanningLotRef = useRef(true);
    const tableH = useRef(500);
    const lotInfo = useRef({
        lot_id: '',
        bag_amount: 0,
        bag_stacking: 0,
        warehouse_location_id: '',
    });

    const processOptions = [
        { label: 'Nhập kho', value: 1 },
        // { label: 'Xuất kho', value: 2 },
    ];

    const columnsTop = [
        { title: "Tổng nhập", dataIndex: "import", key: "import", align: "center", render: (value) => numberFormat(value) },
        { title: "Tổng xuất", dataIndex: "export", key: "export", align: "center", render: (value) => numberFormat(value) }
    ];

    const columnsMiddle = [
        { title: "Mã Lot", dataIndex: "lot_id", key: "lot_id", align: "center", width: '100px' },
        { title: "Số lượng(kg)", dataIndex: "quantity", key: "quantity", align: "center", width: '100px', render: (value) => numberFormat(value) },
        { title: "Vị trí", dataIndex: "warehouse_location_id", key: "warehouse_location_id", align: "center", width: '100px' }
    ];

    const columnsLot = [
        { title: "STT", dataIndex: "stt", key: "stt", align: "center", render: (value, record, index) => index + 1 },
        { title: "Mã Lot", dataIndex: "warehousehistoryable_id", key: "warehousehistoryable_id", align: "center" },
        { title: "Mã BTP", dataIndex: "product_id", key: "product_id", align: "center", render: (_, record) => record?.lot?.product?.id },
        { title: "Số lượng", dataIndex: "quantity", key: "quantity", align: "center", render: (value) => numberFormat(value) },
        { title: "Đơn vị", dataIndex: "unit_id", key: "unit_id", align: "center", render: (_, record) => record?.unit?.unit_name },
        { title: "Vị trí", dataIndex: "warehouse_location_id", key: "warehouse_location_id", align: "center" },
    ];

    const columnsLotScanner = [
        { title: "Mã Lot", dataIndex: "lot_id", key: "lot_id", align: "center" },
        { title: "Bao(NG)", dataIndex: "bag_amount", key: "bag_amount", align: "center", width: 100 },
        { title: "SL(Kg)", dataIndex: "quantity", key: "quantity", align: "center", width: 100, render: (value) => numberFormat(value) },
        // { title: "ĐVT", dataIndex: "unit_name", key: "unit_name", width: 100, align: "center" },
        { title: "Vị trí", dataIndex: "warehouse_location_id", key: "warehouse_location_id", width: 120, align: "center", fixed: "right" }
    ];


    const fetchLot = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance('warehouse-histories', { params: { model: 'lot', type: 'import', is_semi: 1 } })
            setDataLot(response.data?.data ?? []);
            setLoading(false);
        } catch (error) {
            message.error(error.message);
        }
    };

    const fetchSummary = async () => {
        try {
            const response = await summaryWarehouseHistory({ model: 'lot', is_semi: 1 });
            if (response?.success) {
                const data = response.data ?? [];
                const ex = data?.find(item => item?.type === 'export')?.sum ?? 0;
                const im = data?.find(item => item?.type === 'import')?.sum ?? 0;
                setSummary({ export: ex, import: im });
            }
        } catch (error) {
            message.error(error.message);
        }
    }

    useEffect(() => {
        if (selectedProcess === 2) navigate("/oi/semi-finished-product-warehouse-export");
    }, [selectedProcess]);

    useEffect(() => {
        if (context && context.setTitle) {
            context.setTitle('QUẢN LÝ KHO BTP');
        }
    }, [context]);

    useEffect(() => {
        const { warehouse_location_id, lot_id, quantity, unit_id, unit_name, bag_amount } = dataLotScanner;
        setTableDataLotScanner([{
            key: 1,
            warehouse_location_id,
            lot_id,
            quantity,
            unit_id,
            unit_name,
            bag_amount
        }]);
    }, [dataLotScanner]);

    useEffect(() => {
        fetchSummary();
        fetchLot();
    }, []);

    const handleCancel = () => {
        setScannerKey(Date.now());
        setIsModalVisible(false);
        setDataLotScanner({
            warehouse_location_id: null,
            lot_id: null,
            quantity: 0,
            unit_id: null,
        })
        setTableDataLotScanner([]);
        lotInfo.current = {
            lot_id: '',
            bag_amount: 0,
            bag_stacking: 0,
            warehouse_location_id: '',
        };
        isScanningLotRef.current = true;
    };

    const handleCreateWarehouseEntry = async () => {
        try {
            const { warehouse_location_id, lot_id, quantity, unit_id } = dataLotScanner;

            if (!lot_id) throw new Error('CHƯA QUÉT MÃ LOT!');
            if (!warehouse_location_id) throw new Error('CHƯA QUÉT VỊ TRÍ!');
            const response = await warehouseEntryManagementApi.createWarehouseEntryLot({
                warehouse_location_id,
                lot_id,
                quantity,
                unit_id,
                status: 'semi',
                is_semi: 1,
                bag_amount: lotInfo.current.bag_amount,
                bag_stacking: lotInfo.current.bag_stacking,
            });

            if (response?.success) {
                fetchLot();
                handleCancel();
            }
        } catch (error) {
            message.error(error.message);
        }
    }

    // const handleQrScanner = async (data) => {
    //     if (data && isScanningRef.current) {
    //         isScanningRef.current = false;

    //         setLoading(true);
    //         try {
    //             console.log(data);
    //             const response = await warehouseEntryManagementApi.getWarehouseEntries({ lot_id: data });
    //             if (response.data) {
    //                 const dataWarehouseEntry = response.data?.data ?? [];
    //                 if (!dataWarehouseEntry[0]?.id) throw new Error('KHÔNG LẤY ĐƯỢC VỊ TRÍ!');
    //                 const { warehouse_location_id } = dataWarehouseEntry[0]; // Get quantity and location_id from WarehouseEntry
    //                 const result = dataWarehouseEntry[0];
    //                 if (result?.id) { // Check lot_id
    //                     // Get lot
    //                     const resLot = await lotApi.getLotById(data);
    //                     if (resLot?.success) {
    //                         const { product, quantity } = resLot.data;
    //                         await setDataLotScanner(prevState => ({ ...prevState, lot_id: data, unit_id: product?.unit?.id, unit_name: product?.unit?.unit_name, quantity, warehouse_location_id }));
    //                     }
    //                 } else throw new Error('MÃ LOT KHÔNG HỢP LỆ!');
    //             }
    //         } catch (error) {
    //             message.error(error.message);
    //         } finally {
    //             setLoading(false);
    //         }

    //         setTimeout(() => {
    //             isScanningRef.current = true;
    //         }, 2000);
    //     }
    // }

    const handleQrScanner = async (data) => {
        if (data && isScanningRef.current) {
            isScanningRef.current = false;

            setLoading(true);
            try {
                const response = await axiosInstance.get(config.apiUrl + `/lots-ng/${data}`);
                if (response.data) {
                    const {lot_history, product} = response.data;
                    if (!lot_history?.quantity_ng) {
                        throw new Error(`KHÔNG TÌM THẤY BAO NG (${data})`);
                        // return;
                    }
                    const sumBagAmount = lot_history?.quality_ng_check?.reduce((a, b) => (a + Number(b?.quantity_ng)), 0);
                    if (sumBagAmount === 0 || !sumBagAmount) throw new Error(`LOT ${data} KHÔNG CÓ BAO NG!`);
                    if (lotInfo.current.bag_amount >= sumBagAmount) {
                        message.info(`ĐÃ QUÉT HẾT ${lotInfo.current.bag_amount} BAO(NG) LOT ${data}!`);
                        return;
                    }
                    
                    if (!lotInfo.current.lot_id) {
                        const bagStacking = parseInt(Number(lot_history?.quantity_ng) / sumBagAmount);
                        lotInfo.current.lot_id = data;
                        lotInfo.current.bag_stacking = bagStacking;
                        lotInfo.current.bag_amount = 1;
                    } else {
                        if (data === lotInfo.current.lot_id) lotInfo.current.bag_amount += 1;
                        else {
                            message.info(`LOT KHÔNG HỢP LỆ!. ĐANG THỰC HIỆN QUÉT LOT ${lotInfo.current.lot_id}`);
                            return;
                        }
                    }
                    setDataLotScanner((prev) => ({
                        ...prev,
                        lot_id: data,
                        unit_id: product?.unit?.id,
                        unit_name: product?.unit?.unit_name,
                        bag_amount: lotInfo.current.bag_amount,
                        quantity: Number(lotInfo.current.bag_amount * lotInfo.current.bag_stacking)
                    }));
                } else {
                    // Get location
                    const resLocation = await axiosInstance.get(config.apiUrl + `/warehouse-locations/${data}`);
                    if (resLocation.success) {
                        lotInfo.current.warehouse_location_id = data;
                        setDataLotScanner((prev) => ({...prev, warehouse_location_id: data}));
                    }
                }
            } catch (error) {
                message.error(error.message);
            } finally {
                setLoading(false);
            }

            setTimeout(() => {
                isScanningRef.current = true;
            }, 2000);
        }
    }

    const handleOkScanner = async () => {
        handleCreateWarehouseEntry();
    }

    const handleOpenLot = async () => {
        setScannerKey(Date.now());
        setIsModalVisible(true);
    }

    useEffect(() => {
        setTimeout(() => {
            let tableHCurrent = tableHeight("table-sfd-import");
            if (tableHCurrent) {
                tableH.current = tableHCurrent;
            }
        }, 500);
    }, [dataLot]);

    return (
        <Content className="oi-content">
            <Row className="mt-1" gutter={[6, 12]}>
                <Col span={24} className='section-top'>
                    <div className="oi-select-row">
                        <div className="oi-select-container" style={{ width: '30%' }}>
                            <div className="oi-select-header">Thao tác</div>
                            <Select
                                optionFilterProp='label'
                                options={processOptions}
                                value={selectedProcess}
                                style={{ width: '100%', height: '-webkit-fill-available' }}
                                onChange={setSelectedProcess}
                                className="oi-select"
                                placeholder="Chọn công đoạn"
                            />
                        </div>
                        <div className="oi-statistics-container" style={{ width: '70%' }}>
                            <Table
                                className="custom-table"
                                dataSource={[{ ...summary }]}
                                columns={columnsTop.map(col => ({ ...col, align: 'center' }))}
                                pagination={false}
                                size="small"
                                bordered
                            />
                        </div>
                    </div>
                </Col>
                <Col span={20} xs={24} md={20} className='section-middle'>
                    <Table
                        className="custom-table"
                        dataSource={[{ key: 1, ...dataLotScanner }]}
                        columns={columnsMiddle.map(col => ({ ...col, align: 'center' }))}
                        pagination={false}
                        size="small"
                        bordered
                    />
                </Col>
                <Col span={4} xs={24} md={4}>
                    <Button style={{ width: '100%', height: '100%', fontSize: '16px' }} type="primary" icon={<QrcodeOutlined />} onClick={handleOpenLot}>
                        Quét QR
                    </Button>
                </Col>
                <Col span={24}>
                    <Table
                        className='table-sfd-import'
                        loading={loading}
                        dataSource={dataLot}
                        columns={columnsLot.map(col => ({ ...col, align: 'center' }))}
                        pagination={false}
                        size="small"
                        bordered
                        scroll={{ x: '700px', y: tableH.current }}
                    />
                </Col>
            </Row>

            {/* Modal Material QR Scanner */}
            <Modal
                key={scannerKey}
                centered
                title={`Quét QR Lot ${lotInfo.current.lot_id ? `${lotInfo.current.lot_id}(${lotInfo.current.bag_amount})` : ''} ${lotInfo.current?.warehouse_location_id ? `(Vị trí: ${lotInfo.current.warehouse_location_id})` : ''}`}
                open={isModalVisible}
                onCancel={handleCancel}
                onOk={handleOkScanner}
                cancelText="Thoát"
                okText="Xác nhận"
                width={600}
            >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <div>
                        {isModalVisible && <Scanner key={scannerKey} isScan={true} onResult={(res) => handleQrScanner(res)} />}
                    </div>

                    <Table
                        className="custom-table"
                        dataSource={tableDataLotScanner}
                        columns={columnsLotScanner}
                        pagination={false}
                        size="small"
                        bordered
                        scroll={{x: 500}}
                    />
                </Space>
            </Modal>
        </Content>
    );
};

export default OISemiFinishedProductWarehouse;
