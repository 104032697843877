import { QrcodeOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, InputNumber, Layout, Modal, Row, Select, Space, Table, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import warehouseEntryManagementApi from '../../../../api/warehouseEntryManagementApi';
import Scanner from '../../../../components/Scanner';
import lotApi from '../../../../api/lotApi';
import warehouseLocationManagementApi from '../../../../api/warehouseLocationManagementApi';
import { summaryWarehouseHistory } from '../../../../api/warehouseHistoryApi';
import axiosInstance from '../../../../api/axiosInstance';
import { render } from '@testing-library/react';
import { numberFormat } from '../../../../utils/numberFormat';
import { tableHeight } from '../../components/OICalculateTableHeight';
import dayjs from 'dayjs';

const { Content } = Layout;
const { Option } = Select;

const OIFinishedProductWarehouse = () => {
    const navigate = useNavigate();
    const context = useOutletContext();
    const [form] = Form.useForm();
    const [selectedProcess, setSelectedProcess] = useState(1);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataLot, setDataLot] = useState([]);
    const [dataLotScanner, setDataLotScanner] = useState({ // fields in QR
        warehouse_location_id: null,
        lot_id: null,
        quantity: 0,
        unit_id: null,
        unit_name: null,
    });
    const [tableDataLotScanner, setTableDataLotScanner] = useState([]);
    const [summary, setSummary] = useState({ import: 0, export: 0 });
    const [scannerKey, setScannerKey] = useState(Date.now());
    const isScanningRef = useRef(true);
    const isScanningLotRef = useRef(true);
    const tableH = useRef(500);
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());

    const processOptions = [
        { label: 'Nhập kho', value: 1 },
        { label: 'Xuất kho', value: 2 },
    ];

    const columnsTop = [
        { title: "Tổng nhập", dataIndex: "import", key: "import", align: "center", render: (value) => numberFormat(value) },
        { title: "Tổng xuất", dataIndex: "export", key: "export", align: "center", render: (value) => numberFormat(value) }
    ];

    const columnsMiddle = [
        { title: "Mã Lot", dataIndex: "lot_id", key: "lot_id", align: "center", width: '100px' },
        { title: "Số lượng(kg)", dataIndex: "quantity", key: "quantity", align: "center", width: '100px', render: (value) => numberFormat(value) },
        { title: "Vị trí", dataIndex: "warehouse_location_id", key: "warehouse_location_id", align: "center", width: '100px' }
    ];

    const columnsLot = [
        { title: "STT", dataIndex: "stt", key: "stt", align: "center", width: "70px", render: (value, record, index) => index + 1 },
        { title: "Mã Lot", dataIndex: "id", key: "id", align: "center", width: "100px", render: (value, record, index) => record?.lot.id },
        { title: "Mã sản phẩm", dataIndex: "product_id", key: "product_id", align: "center", width: "100px", render: (value, record, index) => record?.lot.product_id },
        { title: "Số lượng", dataIndex: "quantity", key: "quantity", align: "center", width: "100px", render: (value) => numberFormat(value) },
        { title: "Đơn vị", dataIndex: "unit_id", key: "unit_id", align: "center", width: "100px", render: (_, record) => record?.unit?.unit_name },
        { title: "Vị trí", dataIndex: "warehouse_location_id", key: "warehouse_location_id", align: "center", width: "100px" },
        { title: "Mã KH", dataIndex: "customer_id", key: "customer_id", align: "center", width: "100px", render: (_, record) => record?.lot?.batch?.production_plan?.customer_id },
        { title: "PI", dataIndex: "pi", key: "pi", align: "center", width: "100px", render: (_, record) => record?.lot?.batch?.production_plan?.pi },
    ];

    const columnsLotScanner = [
        { title: "Mã Lot", dataIndex: "lot_id", key: "lot_id", align: "center" },
        { title: "Số lượng", dataIndex: "quantity", key: "quantity", align: "center", width: 150, render: (value) => numberFormat(value) },
        { title: "ĐVT", dataIndex: "unit_name", key: "unit_name", align: "center" },
        { title: "Vị trí", dataIndex: "warehouse_location_id", key: "warehouse_location_id", align: "center" }
    ];


    const fetchLot = async () => {
        try {
            setLoading(true);
            const start_date = startDate.format('YYYY-MM-DD');
            const end_date = endDate.format('YYYY-MM-DD');
            const response = await axiosInstance('warehouse-histories', { params: { model: 'lot', type: 'import', start_date, end_date, is_semi: 0 } })
            setDataLot(response.data?.data ?? []);
            setLoading(false);
        } catch (error) {
            message.error(error.message);
        }
    };

    const fetchSummary = async () => {
        try {
            const start_date = startDate.format('YYYY-MM-DD');
            const end_date = endDate.format('YYYY-MM-DD');
            const response = await summaryWarehouseHistory({ model: 'lot', start_date, end_date });
            if (response?.success) {
                const data = response.data ?? [];
                const ex = data?.find(item => item?.type === 'export')?.sum ?? 0;
                const im = data?.find(item => item?.type === 'import')?.sum ?? 0;
                setSummary({ export: ex, import: im });
            }
        } catch (error) {
            message.error(error.message);
        }
    }

    useEffect(() => {
        if (selectedProcess === 2) navigate("/oi/finished-product-warehouse-export");
    }, [selectedProcess]);

    useEffect(() => {
        if (context && context.setTitle) {
            context.setTitle('QUẢN LÝ KHO TP');
        }
    }, [context]);

    useEffect(() => {
        const { warehouse_location_id, lot_id, quantity, unit_id, unit_name } = dataLotScanner;
        setTableDataLotScanner([{
            key: 1,
            warehouse_location_id,
            lot_id,
            quantity,
            unit_id,
            unit_name
        }]);
    }, [dataLotScanner]);

    useEffect(() => {
        fetchSummary();
        // fetchLot();
    }, []);

    useEffect(() => {
        fetchLot();
        fetchSummary();
    }, [startDate, endDate]);

    const handleCancel = () => {
        setScannerKey(Date.now());
        setIsModalVisible(false);
        setDataLotScanner({
            warehouse_location_id: null,
            lot_id: null,
            quantity: 0,
            unit_id: null,
        })
        setTableDataLotScanner([]);
        isScanningLotRef.current = true;
    };

    const handleCreateWarehouseEntry = async () => {
        try {
            const { warehouse_location_id, lot_id, quantity, unit_id } = dataLotScanner;

            if (!lot_id) throw new Error('CHƯA CÓ THÔNG TIN MÃ LOT!');
            if (!warehouse_location_id) throw new Error('CHƯA CÓ THÔNG TIN VỊ TRÍ!');
            const response = await warehouseEntryManagementApi.createWarehouseEntryLot({
                warehouse_location_id,
                lot_id,
                quantity,
                unit_id,
                status: 'test',
            });

            if (response?.success) {
                fetchLot();
                handleCancel();
            }
        } catch (error) {
            message.error(error.message);
        }
    }

    const handleQrScanner = async (data) => {
        if (data && isScanningRef.current) {
            isScanningRef.current = false;

            setLoading(true);
            try {
                if (!isScanningLotRef.current) {
                    const resWarehouse = await warehouseLocationManagementApi.getWarehouseLocationById(data);
                    if (resWarehouse?.success) {
                        await setDataLotScanner(prevState => ({ ...prevState, warehouse_location_id: resWarehouse.data?.id }));
                        isScanningLotRef.current = true;
                    }
                } else {
                    // Get lot
                    const resLot = await lotApi.getLotById(data);
                    if (resLot?.success) {
                        const { product, quantity, lot_history } = resLot.data;
                        const quality_check = lot_history?.quality_check ?? null;
                        console.log(lot_history, quality_check)
                        if (quality_check?.result) {
                            if (quality_check.result === 'OK') {
                                await setDataLotScanner(prevState => ({ ...prevState, lot_id: data, unit_id: product?.unit?.id, unit_name: product?.unit?.unit_name, quantity }));
                                isScanningLotRef.current = false;
                            } else message.info(`LOT: ${data} KIỂM TRA (${quality_check.result}) - NHẬP THẤT BẠI`);
                        } else message.info(`LOT: ${data} QC CHƯA KIỂM TRA!`);
                    }
                }
            } catch (error) {
                message.error(error.message);
            } finally {
                setLoading(false);
            }

            setTimeout(() => {
                isScanningRef.current = true;
            }, 2000);
        }
    }

    const handleOkScanner = async () => {
        handleCreateWarehouseEntry();
    }

    const handleOpenLot = async () => {
        setScannerKey(Date.now());
        setIsModalVisible(true);
    }

    const titleModal = () => {
        return dataLotScanner.lot_id ? 'Quét QR Vị trí' : 'Quét QR Lot';
    }

    useEffect(() => {
        setTimeout(() => {
            let tableHCurrent = tableHeight("table-fd-import");
            if (tableHCurrent) {
                tableH.current = tableHCurrent;
            }
        }, 300);
    }, [dataLot]);

    return (
        <Content className="oi-content">
            <Row className="mt-1" gutter={[6, 12]}>
                <Col span={24} className="section-top">
                    <div className="oi-select-row">
                        <div className="oi-select-container" style={{ width: '30%' }}>
                            <div className="oi-select-header">Thao tác</div>
                            <Select
                                optionFilterProp='label'
                                options={processOptions}
                                value={selectedProcess}
                                style={{ width: '100%', height: '-webkit-fill-available' }}
                                onChange={setSelectedProcess}
                                className="oi-select"
                                placeholder="Chọn công đoạn"
                            />
                        </div>
                        <div className="oi-statistics-container" style={{ width: '70%' }}>
                            <Table
                                className="custom-table"
                                dataSource={[{ ...summary }]}
                                columns={columnsTop.map(col => ({ ...col, align: 'center' }))}
                                pagination={false}
                                size="small"
                                bordered
                            />
                        </div>
                    </div>
                </Col>
                <Col span={20} xs={24} md={20} className='section-middle'>
                    <Table
                        className="custom-table"
                        dataSource={[{ key: 1, ...dataLotScanner }]}
                        columns={columnsMiddle.map(col => ({ ...col, align: 'center' }))}
                        pagination={false}
                        size="small"
                        bordered
                    />
                    <div className="oi-select-row" style={{ gap: '5px', marginBottom: 0, marginTop: '5px' }}>
                        <DatePicker defaultValue={dayjs()} format="DD-MM-YYYY" onChange={(date) => { setStartDate(date) }} allowClear={false} />
                        <DatePicker defaultValue={dayjs()} format="DD-MM-YYYY" onChange={(date) => { setEndDate(date) }} allowClear={false} />
                    </div>
                </Col>
                <Col span={4} xs={24} md={4}>
                    <Button style={{ width: '100%', height: '100%', fontSize: '16px' }} type="primary" icon={<QrcodeOutlined />} onClick={handleOpenLot}>
                        Quét QR
                    </Button>
                </Col>
                <Col span={24}>
                    <Table
                        className='table-fd-import'
                        loading={loading}
                        dataSource={dataLot}
                        columns={columnsLot.map(col => ({ ...col, align: 'center' }))}
                        pagination={false}
                        size="small"
                        bordered
                        scroll={{ x: '700px', y: tableH.current }}
                    />
                </Col>
            </Row>

            {/* Modal Material QR Scanner */}
            <Modal
                key={scannerKey}
                centered
                title={titleModal()}
                open={isModalVisible}
                onCancel={handleCancel}
                onOk={handleOkScanner}
                cancelText="Thoát"
                okText="Xác nhận"
                width={500}
            >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <div>
                        <Scanner key={scannerKey} isScan={true} onResult={(res) => handleQrScanner(res)} />
                    </div>

                    <Table
                        className="custom-table"
                        dataSource={tableDataLotScanner}
                        columns={columnsLotScanner}
                        pagination={false}
                        size="small"
                        bordered
                    />
                </Space>
            </Modal>
        </Content>
    );
};

export default OIFinishedProductWarehouse;
