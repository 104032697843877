import React, { useEffect, useState } from 'react';
import { Table, Card, Space, message, Modal, Descriptions, Button } from 'antd';
import './ManufacturingTable.css';
import config from '../../../../config/config';
import ExportExcelButton from '../../../../components/ExportExcelButton';
import dayjs from 'dayjs';
import axiosInstance from '../../../../api/axiosInstance';
import { numberFormat } from '../../../../utils/numberFormat';
import { b64toBlob } from '../../../../utils/dataBlob';
import { DownloadOutlined } from '@ant-design/icons';

// Giả lập dữ liệu cho biểu đồ
const dataChart = [
  { tag: 'SL đầu ra(KH)', quantity: 350, percent: 800 },
  { tag: 'SL đầu ra(TT)', quantity: 900, percent: 600 },
  { tag: 'Chênh lệch', quantity: 300, percent: 400 },
  { tag: 'Số lượng lỗi', quantity: 450, percent: 380 },
];

const ManufacturingTable = ({ filters }) => {
  const [chart, setChart] = useState([])
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [overall, setOverall] = useState({});
  const [loadingTable1, setLoadingTable1] = useState(false);
  const [loadingTable3, setLoadingTable3] = useState(false);
  const [overallManufactureHistoryMiddleData, setOverallManufactureHistoryMiddleData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [recordData, setRecordData] = useState({});

  useEffect(() => {
    setChart(dataChart);
  }, []);

  function getRandomNumber(min, max) {
    return Math.random() * (max - min) + min;
  }

  const overallManufactureHistoryColumn = [
    {
      title: 'Số lượng đầu ra KH',
      dataIndex: 'totalPlanQuantity',
    },
    {
      title: 'Số lượng đầu ra thực tế',
      dataIndex: 'totalActualQuantity',
    },
    {
      title: 'Chênh lệch (TT - KH)',
      dataIndex: 'actual_difference_with_plan',
      render: (value) => Math.abs(Number(value || 0)),
    },
    {
      title: 'Tỷ lệ hoàn thành',
      dataIndex: 'completion_rate',
    },
    {
      title: 'Số lượng NG',
      dataIndex: 'totalNG',
    },
    {
      title: 'Tỷ lệ NG',
      dataIndex: 'ng_rate',
    },
  ];

  const overallManufactureHistoryMiddleColumn = [
    {
      title: 'STT',
      dataIndex: 'no',
      width: 60,
      render: (_, record, index) => index + 1
    },
    {
      title: 'Máy sản xuất',
      dataIndex: 'equipment_id',
      width: 100,
    },
    {
      title: 'Mã nội bộ',
      dataIndex: 'product_id',
      width: 150,
    },
    {
      title: 'Mã đơn hàng',
      dataIndex: 'batch_id',
      width: 150,
    },
    {
      title: 'Khối lượng KH',
      dataIndex: 'plan_quantity',
      width: 120,
      render: (_, record) => record?.batch?.production_plan?.quantity ? numberFormat(record?.batch?.production_plan?.quantity) : '',
    },
    {
      title: 'Tổng NVL',
      dataIndex: 'material',
      width: 120,
      render: (_, record) => {
        if (record?.batch?.production_plan?.bom?.bom_items) {
          return <a onClick={() => showBomInfo(record.batch.production_plan)}>{numberFormat(sumWeight(record.batch.production_plan))}</a>
        }
        return '';
      },
    },
    {
      title: 'Số lượng (Kg/Giờ)',
      dataIndex: 'quantity',
      width: 150,
      render: (_, record) => record?.production_rate_per_hour ? numberFormat(record?.production_rate_per_hour) : '',
    },
    {
      title: 'Tỷ lệ hao hụt (%)',
      dataIndex: 'ty_le_hao_hut',
      width: 120,
      render: (_, record) => {
        if (record?.batch?.production_plan?.bom?.bom_items) {
          return percentLoss(record.batch.production_plan);
        }
        return '';
      },
    },
    {
      title: 'Điện năng tiêu thụ (Kwh)',
      dataIndex: 'power',
      width: 200,
      render: (_, record) => {
        if (record?.power_start && record?.power_end) {
          return numberFormat(Number(record.power_end) - Number(record.power_start));
        }
        return '-';
      }
    },
  ];

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: '60px',
      fixed: 'left',
      render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
    },
    {
      title: 'Số PI',
      dataIndex: 'pi',
      key: 'pi',
      width: '100px',
    },
    {
      title: 'Mã nội bộ',
      dataIndex: 'product_id',
      key: 'product_id',
      width: '120px',
    },
    {
      title: 'Mã khách hàng',
      dataIndex: 'customer_id',
      key: 'customer_id',
      width: '150px',
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'product_name',
      key: 'product_name',
      width: '180px',
      render: (text, record) => record?.product_name,
    },
    {
      title: 'Ngày sản xuất',
      dataIndex: 'productionDate',
      key: 'productionDate',
      width: '120px',
      render: (text, record) => record?.start_time
    },
    {
      title: 'Máy sản xuất',
      dataIndex: 'equipment_id',
      key: 'equipment_id',
      width: '110px',
    },
    {
      title: 'Mã lô sản xuất',
      dataIndex: 'batch_code',
      key: 'batch_code',
      width: '110px',
    },
    {
      title: 'Mã pallet/thùng',
      dataIndex: 'lot_id',
      key: 'lot_id',
      width: '150px',
    },
    {
      title: 'Thời gian bắt đầu',
      dataIndex: 'startTime',
      key: 'startTime',
      width: '130px',
      render: (text, record) => record?.start_time
    },
    {
      title: 'Thời gian kết thúc',
      dataIndex: 'endTime',
      key: 'endTime',
      width: '140px',
      render: (text, record) => record?.end_time
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '90px',
      render: (value) => numberFormat(value),
    },
  ];

  const fetchData = async (filters) => {
    setLoadingTable3(true);
    try {
      setLoading(true);
      if (filters?.start_time) filters.start_time = dayjs(filters.start_time).format('YYYY-MM-DD');
      if (filters?.end_time) filters.end_time = dayjs(filters.end_time).format('YYYY-MM-DD');
      const response = await axiosInstance.get('lot-histories', { params: filters }) // Thay đổi endpoint theo API của bạn
      setData(response.data.data);
      response.data?.pagination && setPagination(response.data.pagination)
      setLoading(false);
    } catch (error) {
      message.error('Failed to fetch pallets');
    }
    setLoadingTable3(false);
  };
  const fetchOverallManufactureHistory = async (filters) => {
    setLoadingTable1(true);
    try {
      const response = await axiosInstance.get('ui-overall-lot-histories', { params: filters });
      setOverall(response.data);
    } catch (error) {
      message.error('Failed to fetch pallets');
    }
    setLoadingTable1(false);
  }

  const fetchBatchHistory = async () => {
    setLoading(true);
    if (filters?.start_time) filters.start_time = dayjs(filters.start_time).format('YYYY-MM-DD');
    if (filters?.end_time) filters.end_time = dayjs(filters.end_time).format('YYYY-MM-DD');
    const response = await axiosInstance.get('/batch-histories', { params: { ...filters, pageSize: 15 } });
    if (response?.success) {
      const resource = await (response.data?.data ?? []).filter((item) => item?.batch?.production_plan?.status !== 4);
      setOverallManufactureHistoryMiddleData(resource);
    }
    setLoading(false);
  }

  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    setPagination(paginate)
    fetchData({ ...filters, ...paginate });
    fetchOverallManufactureHistory(filters);
    fetchBatchHistory();
  }, [filters]);

  const getTableHeight = () => {
    const card = document.querySelector('.section-card');
    if (card) {
      const cardHead = card?.querySelector('.ant-card-head');
      const cardBody = card?.querySelector('.ant-card-body');
      const tableTop = card?.querySelector('.table-top');
      const tableMiddle = card?.querySelector('.table-middle');
      const tableBottom = card?.querySelector('.table-bottom');
      let paddingTop = 0;
      let paddingBottom = 0;
      if (cardBody) {
        const style = window.getComputedStyle(cardBody);
        paddingTop = parseFloat(style.paddingTop);
        paddingBottom = parseFloat(style.paddingBottom);
      }
      const header = tableBottom?.querySelector('.ant-table-thead');
      const tableBottomPagination = tableBottom?.querySelector('.ant-table-pagination');
      const tableBody = tableBottom?.querySelector('.ant-table-body');
      const antEmpty = tableBottom?.querySelector('.ant-empty-normal');
      const result = (card?.offsetHeight ?? 0) - (cardHead?.offsetHeight ?? 0) - (tableTop?.offsetHeight ?? 0) - (tableMiddle?.offsetHeight ?? 0) - paddingTop - paddingBottom - (header?.offsetHeight ?? 0) - (tableBottomPagination?.offsetHeight ?? 0) - 48;
      if (tableBody) tableBody?.style.setProperty('height', `${result}px`, 'important');
      if (antEmpty) antEmpty?.style.setProperty('height', `${result - 114}px`);
      console.log(result);
      if (Number(result) > 0) return result;
    }
    return undefined;
  }

  const showBomInfo = async (record) => {
    await setRecordData(record);
    setIsModalVisible(true);
  }

  /**
   * @param record ProductionPlan 
   */
  const sumWeight = (record) => {
    const volume_of_batch = Number(record?.volume_of_batch);
    if (record.bom?.bom_items?.length === 0 || !volume_of_batch) return 0;
    let sum = 0;
    record.bom?.bom_items?.map((item) => {
      sum += Number(item?.unit_weight) * Number(volume_of_batch) * Number(record?.batch_number);
    });
    return sum;
  }

  const percentLoss = (record) => {
    if (record.bom?.bom_items?.length > 0 && record?.quantity > 0) {
      const sumW = sumWeight(record);
      const balance = sumW - Number(record.quantity);
      if (balance > 0 && sumW > 0) return `${numberFormat(balance / sumW * 100)}%`;
    }
    return '';
  }

  const handleExportExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/production-plans-export/manufacturing-history', {params: filters});
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        const blob = b64toBlob(data, type)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  return (
    <Card
      className="section-card"
      style={{ height: '100%' }}
      title="Lịch sử sản xuất"
      extra={
        <Space>
          {/* <ExportExcelButton data={overallManufactureHistoryMiddleData} filename={'Manufacturing'} sheetname={'Manufacturing'} /> */}
          <Button type="primary" icon={<DownloadOutlined />} onClick={handleExportExcel} loading={loading}>Xuất file</Button>
        </Space>

      }
    >
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 8 }}>
        <Table
          className='table-top'
          loading={loadingTable1}
          columns={overallManufactureHistoryColumn.map(e => ({ ...e, align: 'center' }))}
          dataSource={Object.keys(overall).length > 0 ? [overall] : []}
          bordered
          pagination={false}
          size='small'
          scroll={{ x: 800 }}
        />
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Table
            className='table-middle'
            columns={overallManufactureHistoryMiddleColumn.map(e => ({ ...e, align: 'center' }))}
            dataSource={overallManufactureHistoryMiddleData}
            bordered
            pagination={false}
            size='small'
            scroll={{ x: 1300, y: '15vh' }}
          />
          <Table
            className='table-bottom'
            loading={loadingTable3}
            columns={columns.map(e => ({ ...e, align: 'center' }))}
            dataSource={data}
            bordered
            pagination={{
              current: pagination.page,
              pageSize: pagination.pageSize,
              total: pagination.total,
              onChange: (page, pageSize) => fetchData({ ...filters, page, pageSize: pageSize }),
            }}
            scroll={{ x: '1600px', y: getTableHeight() ?? '32vh' }}
            size='small'
          />
        </Space>
      </div>

      {/* Modal BomInfo */}
      <Modal
        centered
        title={`Thông tin đơn ${recordData.batch_id}`}
        open={isModalVisible}
        width={1200}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Descriptions title="" bordered size='small'>
          <Descriptions.Item label="Đơn sản xuất">{recordData.batch_id}</Descriptions.Item>
          <Descriptions.Item label="Ngày sản xuất">{recordData.start_time && dayjs(recordData.start_time).format('DD/MM/YYYY HH:mm')}</Descriptions.Item>
          <Descriptions.Item label="Ngày kết thúc">{recordData.end_time && dayjs(recordData.end_time).format('DD/MM/YYYY HH:mm')}</Descriptions.Item>
          <Descriptions.Item label="Máy">{recordData?.equipment?.equipment_name}</Descriptions.Item>
          <Descriptions.Item label="Loại công suất (Kg/h)">{recordData.capacity}</Descriptions.Item>
          <Descriptions.Item label="Thời gian sản xuất">{recordData.production_time}</Descriptions.Item>
          <Descriptions.Item label="Số PI">{recordData.pi}</Descriptions.Item>
          <Descriptions.Item label="Mã nội bộ">{recordData.product_id}</Descriptions.Item>
          <Descriptions.Item label="Mã khách hàng">{recordData.customer_id}</Descriptions.Item>
          <Descriptions.Item label="Mã lô">{recordData.batch_code}</Descriptions.Item>
          <Descriptions.Item label="Khối lượng">{recordData.quantity}</Descriptions.Item>
          <Descriptions.Item label="Số mẻ">{recordData.batch_number}</Descriptions.Item>
          {/* <Descriptions.Item label="Số mẻ">{numberFormat(recordData.quantity / recordData.volume_of_batch)}</Descriptions.Item> */}
          <Descriptions.Item label="Khối lượng/mẻ">{recordData.volume_of_batch}</Descriptions.Item>
          <Descriptions.Item label="Loại bao bì">{recordData.packaging_type}</Descriptions.Item>
          <Descriptions.Item label="Lot Pallet">{recordData.lot_pallet}</Descriptions.Item>
          <Descriptions.Item label="Khối lượng bao">{recordData.bag_stacking}</Descriptions.Item>
          <Descriptions.Item label="Xếp Pallet">{recordData.pallet_stacking}</Descriptions.Item>
          <Descriptions.Item label="Màng co, shipping">{recordData.shrink_wrap}</Descriptions.Item>
        </Descriptions>
        <Table
          style={{ marginTop: '10px' }}
          columns={[
            { title: 'STT', dataIndex: 'stt', width: '50px', align: 'center', fixed: 'left', render: (_, item, index) => index + 1 },
            { title: 'Mã nguyên liệu', align: 'center', dataIndex: 'component_id' },
            { title: 'Mô tả thành phẩm', align: 'center', dataIndex: 'component_description', width: 200 },
            { title: 'Tỉ lệ thành phần', align: 'center', dataIndex: 'unit_weight', width: 150, render: (value) => `${Number(parseFloat(value * 100).toFixed(2))}%` },
            { title: 'Khối lượng thành phần', align: 'center', dataIndex: 'unit_weight', width: 190, render: (_, item) => (item.unit_weight * recordData.volume_of_batch).toFixed(2) + ' kg' },
          ]}
          // dataSource={recordData?.product?.boms[0]?.bom_items}
          dataSource={recordData?.bom?.bom_items}
          rowSelection={false}
          bordered
          pagination={false}
          scroll={{ x: '1000px' }}
          size='small'
        />
      </Modal>
    </Card>
  );
};

export default ManufacturingTable;
