import React, { useEffect, useState } from 'react';
import { Table, Button, Card, Space, message } from 'antd';
import config from '../../../../config/config';
import lotHistoryApi from '../../../../api/lotHistoryApi';
import dayjs from 'dayjs';
import ExportExcelButton from '../../../../components/ExportExcelButton';
import axiosInstance from '../../../../api/axiosInstance';
import { b64toBlob } from '../../../../utils/dataBlob';
import { DownloadOutlined } from '@ant-design/icons';

const PQCTable = ({ filters }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const columns = [
    { title: 'STT', dataIndex: 'stt', render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1, width: '50px' },
    { title: 'Ngày kiểm tra', dataIndex: 'checked_date', width: '150px', render: (_, record) => dayjs(record?.quality_check?.created_at).format('DD/MM/YYYY HH:mm:ss') },
    { title: 'Công đoạn', dataIndex: 'proccess_id', width: '100px', render: (_, record) => record?.equipment?.process?.process_name, hidden: true },
    { title: 'Máy sản xuất', dataIndex: 'equipment_id', width: '150px' },
    { title: 'Mã sản phẩm', dataIndex: 'product_id', width: '150px' },
    { title: 'Mã Lot', dataIndex: 'lot_id', width: '100px' },
    { title: 'Số lượng đầu ra', dataIndex: 'quantity', width: '120px', render: (value) => Number(Number(value).toFixed(2)).toLocaleString('en-US') },
    { title: 'SL đầu ra OK', dataIndex: 'quantity_ok', width: '120px', render: (_, record) => Number(record?.quantity) - Number(record?.quantity_ng) },
    { title: 'Số lượng NG', dataIndex: 'quantity_ng', width: '100px', render: (value) => Number(Number(value).toFixed(2)).toLocaleString('en-US') },
    { title: 'Tỷ lệ NG', dataIndex: 'failure_percentage', width: '100px', render: (_, record) => record?.quantity > 0 ? (Number(Number(record?.quantity_ng ?? 0) / Number(record?.quantity ?? 1) * 100).toFixed(2) + '%') : '0%' },
    { title: 'Kết quả KT', dataIndex: 'quality_check', width: '100px', render: (value) => value?.result },
    { title: 'Người kiểm tra', dataIndex: 'checker_name', width: '120px', render: (_, record) => record?.quality_check?.inspector?.name }
  ];


  const fetchData = async (filters) => {
    try {
      setLoading(true);
      filters.quality_check = 'PQC';
      filters.check_type = 'PQC';
      if (filters?.start_date) filters.start_date = dayjs(filters.start_date).format('YYYY-MM-DD');
      if (filters?.end_date) filters.end_date = dayjs(filters.end_date).format('YYYY-MM-DD');

      const response = await lotHistoryApi.getLotHistories(filters); // Thay đổi endpoint theo API của bạn
      setData(response.data.data);
      response.data?.pagination && setPagination(response.data.pagination)
      setLoading(false);
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    setPagination(paginate)
    fetchData({ ...filters, ...paginate });
  }, [filters]);

  const getTableHeight = () => {
    const layout = document.querySelector('.site-layout-content');
    if (layout) {
      const sectionChart = layout?.querySelector('.section-chart');
      const sectionCard = layout?.querySelector('.section-card');
      const cardHead = sectionCard?.querySelector('.ant-card-head');
      const cardBody = sectionCard?.querySelector('.ant-card-body');
      const table = sectionCard?.querySelector('.table-pqc');
      const tableBody = table?.querySelector('.ant-table-body');
      const antEmpty = table?.querySelector('.ant-empty-normal');
      const tableHeader = table?.querySelector('.ant-table-thead');
      const tablePagination = table?.querySelector('.ant-table-pagination');
      let paddingTop = 0;
      let paddingBottom = 0;
      if (cardBody) {
        const style = window.getComputedStyle(cardBody);
        paddingTop = parseFloat(style.paddingTop);
        paddingBottom = parseFloat(style.paddingBottom);
      }
      console.log(layout?.offsetHeight, sectionChart?.offsetHeight);
      const result = (layout?.offsetHeight ?? 0) - (sectionChart?.offsetHeight ?? 0) - (cardHead?.offsetHeight ?? 0) - (tableHeader?.offsetHeight ?? 0) - (tablePagination?.offsetHeight ?? 0) - paddingTop - paddingBottom - 95;
      if (tableBody) tableBody?.style.setProperty('height', `${result}px`, 'important');
      if (antEmpty) antEmpty?.style.setProperty('height', `${result}px`);
      console.log(result);
      if (Number(result)) return result;
    }
    return undefined;
  }

  const handleExportExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/quality-export/pqc', {params: filters});
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        const blob = b64toBlob(data, type)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  const loadMoreData = async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    filters.quality_check = 'PQC';
    filters.check_type = 'PQC';
    if (filters?.start_date) filters.start_date = dayjs(filters.start_date).format('YYYY-MM-DD');
    if (filters?.end_date) filters.end_date = dayjs(filters.end_date).format('YYYY-MM-DD');

    const response = await lotHistoryApi.getLotHistories({ ...filters, page, pageSize: 20 });
    const result = response.data?.data ?? [];
    if (result.length > 0) {
      setData((prevData) => [...prevData, ...result]);
      setPage((prevPage) => prevPage + 1);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (scrollTop + clientHeight >= scrollHeight - 15) {
      loadMoreData();
    }
  };

  return (
    // <Card
    //   className='section-card'
    //   size="small"
    //   title="Bảng chi tiết lỗi"
    //   extra={
    //     <Space>
    //       <Button type="primary" icon={<DownloadOutlined />} onClick={handleExportExcel} loading={loading}>Xuất file</Button>
    //     </Space>
    //   }
    // >
    //   <Table
    //     className='table-pqc'
    //     loading={loading}
    //     columns={columns.map(e => ({ ...e, align: 'center' }))}
    //     dataSource={data}
    //     bordered
    //     pagination={{
    //       current: pagination.page,
    //       pageSize: pagination.pageSize,
    //       total: pagination.total,
    //       onChange: (page, pageSize) => {
    //         fetchData({ ...filters, page, pageSize });
    //       }
    //     }}
    //     scroll={{ x: '1330px', y: getTableHeight() }}
    //     size='small'
    //   />
    // </Card>
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
        <b>Bảng chi tiết lỗi</b>
        <Button size="small" type="primary" icon={<DownloadOutlined />} onClick={handleExportExcel} loading={loading}>Xuất file</Button>
      </div>
      <Table
          className='table-pqc'
          loading={loading}
          columns={columns.map(e => ({ ...e, align: 'center' }))}
          dataSource={data}
          bordered
          pagination={false}
          scroll={{ x: '1330px', y: getTableHeight() }}
          size='small'
          onScroll={handleScroll}
        />
    </div>
  );
};

export default PQCTable;
