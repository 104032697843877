import { Modal, Table, Form, Input, Row, Col, Button, Select, InputNumber, message, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../../api/axiosInstance';
import { CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import materialManagementApi from '../../../../api/materialManagementApi';

const { Option } = Select;

const MaintenanceTab = (equipmentId) => {

  const [listMaintenance, setListMaintenance] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  // const [parts, setParts] = useState([]); // { key: Date.now(), name: '', quantity: 0 }
  const [materialOptions, setMaterialOptions] = useState([]);
  const [maintenanceSelected, setMaintenanceSelected] = useState([]);

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      width: '50px',
      render: (_, record, index) => index + 1,
    },
    {
      title: 'Hạng mục bảo dưỡng',
      dataIndex: 'maintenance_type',
      width: '200px',
    },
    {
      title: 'Nội dung bảo dưỡng',
      dataIndex: 'notes',
      width: '200px',
    },
    {
      title: 'Vị trí',
      dataIndex: 'maintenance_unit',
      width: '100px',
    },
    {
      title: 'Giải thích',
      dataIndex: 'explain',
      width: '200px',
    },
    {
      title: 'Ghi chú',
      dataIndex: 'notes',
      width: '200px',
    },
    {
      title: 'Bắt đầu',
      dataIndex: 'start_time',
      width: '80px',
      render: (value) => value ? dayjs(value).format('HH:mm') : '-',
    },
    {
      title: 'Kết thúc',
      dataIndex: 'end_time',
      width: '80px',
      render: (value) => value ? dayjs(value).format('HH:mm') : '-',
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      width: '120px',
      fixed: 'right',
      render: (_, record) => {
        if (!record?.start_time) return <Button type="primary" size="small" onClick={() => handleSaveStartTime(record?.id)} >Bắt đầu</Button> // Start time
        else if (record?.start_time && !record?.end_time) return <Button type="primary" size="small" onClick={() => showModal(record)} >Nhập kết quả</Button> // End time 
        else return <Button size="small">Đã nhập</Button> // Stop
      }
    }
  ];
  const fetchMaintenance = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/oi-maintenance-plan', { params: { equipment_id: equipmentId } });
      setListMaintenance(response.data);
      console.log('Maintenance: ', response.data);
    } catch (error) {
      console.log('Failed to fetch maintenance: ', error);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchMaintenance();
  }, [equipmentId]);

  useEffect(() => {
    fetchMaterial();
  }, []);

  const showModal = (record) => {
    setMaintenanceSelected(record);
    form.setFieldsValue({ maintenance_type: record?.maintenance_type, notes: record?.notes });
    setVisible(true);
  }

  // const handleAddField = () => {
  //   setParts([...parts, { key: Date.now(), name: '', quantity: 0 }]);
  // }
  // const handleRemoveField = (key) => {
  //   setParts(parts.filter(part => part.key !== key));
  // }
  // const handleFieldChange = (key, field, value) => {
  //   setParts(parts.map(part => {
  //     if (part.key === key) {
  //       return { ...part, [field]: value };
  //     }
  //     return part;
  //   }));
  // }

  const handleSaveStartTime = async (id) => {
    setLoading(true);
    try {
      const response = await axiosInstance.put(`/maintenance-plan/${id}`, { start_time: dayjs().format('YYYY-MM-DD') });
      if (response?.success) fetchMaintenance();
    } catch (error) {
      console.log(error.message);
    }
    setLoading(false);
  }

  const handleOk = async () => {
    try {
      if (!maintenanceSelected?.id) throw new Error('VUI LÒNG CHỌN HẠNG MỤC BẢO DƯỠNG!');
      setLoading(true);
      form.validateFields().then(async (values) => {
        const material_items = values.materialItems;
        delete values.materialItems;
        const params = {
          ...values,
          material_items,
          end_time: dayjs().format('YYYY-MM-DD')
        }
        const response = await axiosInstance.put(`/maintenance-plan/${maintenanceSelected.id}`, params);
        if (response?.success) {
          form.resetFields();
          fetchMaintenance();
          setVisible(false);
        }
      }).catch((error) => console.log(error));
      setLoading(false);
    } catch (error) {
      message.error(error.message);
    }
  }

  const fetchMaterial = async (params = {}) => {
    try {
      params.type = 3;
      const response = await materialManagementApi.getMaterials(params);
      await setMaterialOptions((response.data.data ?? []).map((item) => {
        return { label: item?.material_name, value: item?.id };
      }));
      if ((response.data.data ?? []).length === 0) message.info('DỮ LIỆU PHỤ TÙNG TRỐNG!')
    } catch (error) {
      message.error(error.message);
    }
  }

  return (
    <>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 8 }}>
        <Table
          loading={loading}
          dataSource={listMaintenance}
          columns={columns.map(e => ({ ...e, align: 'center' }))}
          pagination={false}
          size="small"
          scroll={{ x: '910px', y: 'calc(100vh - 300px)' }}
          bordered
        />
      </div>
      <Modal
        title={'Thông tin chi tiết'}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={handleOk}
        okText="Xác nhận"
        cancelText="Hủy"
      >
        <Form
          form={form}
          layout='vertical'
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Nội dung bảo dưỡng"
                name="maintenance_type"
              >
                <Input placeholder="Nội dung bảo dưỡng" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Giải thích"
                name="explain"
              >
                <Input placeholder="Giải thích" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.List name="materialItems">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row gutter={8} key={key}>
                        <Col span={14} xs={10} md={14}>
                          <Form.Item
                            {...restField}
                            name={[name, 'material_id']}
                            label={key === 0 ? "Tên phụ tùng" : ""}
                            rules={[
                              {
                                required: true,
                                message: 'Vui lòng nhập!',
                              },
                            ]}
                          >
                            <Select placeholder="Phụ tùng thay thế" options={materialOptions.map(e => (form.getFieldValue('materialItems') ?? []).some(o => o?.material_id === e.value) ? ({ ...e, disabled: true }) : e)} showSearch optionFilterProp='label' />
                          </Form.Item>
                        </Col>
                        <Col span={8} xs={9} md={8}>
                          <Form.Item
                            {...restField}
                            name={[name, 'quantity']}
                            label={key === 0 ? "Số lượng" : ""}
                            rules={[
                              {
                                required: true,
                                message: 'Vui lòng nhập!',
                              },
                            ]}
                          >
                            <InputNumber min={0} placeholder="Số lượng" style={{ width: '100%' }} />
                          </Form.Item>
                        </Col>
                        <Col span={2} xs={5} md={2}>
                          <Form.Item label={key === 0 ? " " : ""}>
                            <Button danger block icon={<DeleteOutlined />} onClick={() => remove(name)} />
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Thêm phụ tùng thay thế
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>

            {/* {parts.map(part => (
              <React.Fragment key={part.key}>
                <Col span={14}>
                  <Form.Item
                    label="Tên phụ tùng thay thế"
                    name="material_id"
                  >
                    <Select
                      value={part.name}
                      onChange={(value) => handleFieldChange(part.key, 'name', value)}
                    >
                      <Option value="part1">Phụ tùng 1</Option>
                      <Option value="part2">Phụ tùng 2</Option>
                      <Option value="part3">Phụ tùng 3</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Số lượng"
                    name="quantity"
                  >
                    <Input
                      type="number"
                      value={part.quantity}
                      onChange={(e) => handleFieldChange(part.key, 'quantity', e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Button
                    danger
                    type="primary"
                    icon={<DeleteOutlined />}
                    onClick={() => handleRemoveField(part.key)}
                    style={{ marginTop: '30px' }}
                  >
                  </Button>
                </Col>
              </React.Fragment>
            ))}
            <Col span={24}>
              <Button type="dashed" onClick={handleAddField} style={{ width: '100%' }}>
                Thêm phụ tùng
              </Button>
            </Col> */}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default MaintenanceTab;
