import React, { useState, useEffect, useRef, useContext } from 'react';
import { Table, Button, Space, Modal, Form, Input, Card, Upload, message, Popconfirm, Select, InputNumber } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, PrinterOutlined, DownloadOutlined } from '@ant-design/icons';
import warehouseLocationApi from "../../../api/warehouseLocationManagementApi";
import warehouseApi from '../../../api/warehouseManagementApi';
import config from '../../../config/config';
import axiosInstance from '../../../api/axiosInstance';
import { b64toBlob } from '../../../utils/dataBlob';
import AuthContext from '../../../context/AuthContext';

const WarehouseLocationTable = ({ filters }) => {
  const { user } = useContext(AuthContext);
  const [permissions, setPermissions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [editingRecord, setEditingRecord] = useState(null);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });
  const [warehouses, setInventories] = useState([]);

  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    setPagination(paginate);
    fetchData({ ...filters, ...paginate });
  }, [filters]);

  useEffect(() => {
    (async () => {
      var res = await warehouseApi.getInventories();
      if (res.success) {
        setInventories(res.data.data)
      }
    })();
  }, []);

  useEffect(() => {
    setPermissions([...user?.role?.permissions?.map(({ slug }) => slug)]);
  }, []);

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      const response = await warehouseLocationApi.getWarehouseLocations(filters);
      if (response.success) {
        setData(response.data.data);
        response.data.pagination && setPagination(response.data.pagination);
      }
      setLoading(false);
    } catch (error) {
      message.error('Failed to fetch warehouse locations');
    }
  };

  const showModal = () => {
    setIsEditMode(false);
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  const showEditModal = (record) => {
    setIsEditMode(true);
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    form.validateFields().then(async (values) => {
      try {
        if (isEditMode) {
          const response = await warehouseLocationApi.updateWarehouseLocation(editingRecord.id, values);
          if (response.success) {
            setData((prevData) => [{ ...values }, ...prevData.filter(item => item.id !== editingRecord.id)]);
          }
        } else {
          const response = await warehouseLocationApi.createWarehouseLocation(values);
          if (response.success) {
            setData((prevData) => [{ ...values, id: response.data.id }, ...prevData]);
            setPagination({ ...pagination, total: pagination.total + 1 });
          }
        }
        setIsModalVisible(false);
        form.resetFields();
      } catch (error) {
        message.error('Failed to save warehouse location');
      }
    }).catch(error => {
      console.log(error);
    })
  };

  const handleDelete = async (id) => {
    try {
      const response = await warehouseLocationApi.deleteWarehouseLocation(id);
      if (response.success) {
        setData((prevData) => prevData.filter(item => item.id !== id));
      }
    } catch (error) {
      message.error('Failed to delete warehouse location');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const handleUploadChange = (info) => {
    try {
      if (info.file.status === 'uploading') {
        setUploading(true);
      } else {
        if (info.file.status === 'done') {
          if (!info.file?.response?.success)
            throw new Error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`)
          fetchData({ ...filters, ...pagination });
          message.success(`${info.file.name} - Upload thành công`);
        } else if (info.file.status === 'error') {
          message.error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`);
        }
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setUploading(false);
    }
  };

  const uploadProps = {
    name: 'file',
    accept: '.xlsx, .xls',
    action: config.apiUrl + '/import-warehouse-locations',
    onChange: handleUploadChange,
    showUploadList: false,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'), // Thêm token vào header
    },
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
    },
    {
      title: 'Mã kho',
      dataIndex: 'warehouse_id',
      key: 'warehouse_id',
      align: 'center',
    },
    {
      title: 'Vị trí',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'Capacity',
      dataIndex: 'capacity',
      key: 'capacity',
      align: 'center',
    },
    {
      title: 'Loại',
      dataIndex: 'location_type',
      key: 'location_type',
      align: 'center',
    },
    {
      title: 'Hành động',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {permissions.includes('warehouse_location.update') && (<Button type='primary' size='small' icon={<EditOutlined />} onClick={() => showEditModal(record)} />)}
          {permissions.includes('warehouse_location.delete') && (<Popconfirm
            title="Bạn có chắc chắn muốn xóa bản ghi này?"
            onConfirm={() => handleDelete(record.id)}
            okText="Có"
            cancelText="Không"
          >
            <Button type='primary' size='small' icon={<DeleteOutlined />} danger />
          </Popconfirm>)}
        </Space>
      ),
      align: 'center',
    },
  ];

  const handleExportExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/warehouse-locations-export');
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        const blob = b64toBlob(data, type)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  return (
    <Card
      title="Quản lý vị trí kho"
      extra={
        <div className="table-actions">
          <Button target="_blank" href={config.apiUrl.replace('/api', '') + '/excel/WarehouseLocation.xlsx'} icon={<DownloadOutlined />}>Tải excel mẫu</Button>
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />} type="primary" loading={uploading} style={{ margin: '0 8px' }}>
              {uploading ? 'Đang tải lên' : 'Upload excel'}
            </Button>
          </Upload>
          <Button type="primary" icon={<DownloadOutlined />} onClick={handleExportExcel}>Xuất dữ liệu</Button>
          <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>Thêm</Button>
        </div>
      }
    >
      <Table
        loading={loading}
        // rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: (page, pageSize) => {
            setPagination({ ...pagination, page, pageSize });
            fetchData({ ...filters, page, pageSize });
          }
        }}
        bordered
        size="small"
      />
      <Modal
        title={
          <div className="modal-title">
            {isEditMode ? "Chỉnh sửa" : "Thêm"}
            <div className="modal-title-divider"></div>
          </div>
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Lưu lại"
        cancelText="Thoát"
        width={800}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <div className="modal-form-grid">
            <Form.Item name="warehouse_id" label="Mã kho" rules={[{ required: true, message: 'Vui lòng chọn kho!' }]}>
              <Select options={warehouses.map(e => ({ ...e, value: e.id, label: e.warehouse_name }))} optionFilterProp='label' showSearch />
            </Form.Item>
            <Form.Item name="id" label="Mã vị trí" rules={[{ required: true, message: 'Vui lòng nhập mã vị trí!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="capacity" label="Capacity" rules={[{ required: true, message: 'Vui lòng nhập sức chứa!' }]}>
              <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>
            <Form.Item name="location_type" label="Loại vị trí">
              <Input placeholder="Loại vị trí" />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </Card>
  );
};

export default WarehouseLocationTable;
