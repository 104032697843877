// src/pages/Dashboard/DashboardPage.js
import React, { useState, useContext } from 'react';
import { LogoutOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Row, Typography, Space } from "antd";
import { withRouter, Link } from "react-router-dom";
import config from '../../config/config';
import AuthContext from '../../context/AuthContext';

const DashboardPage = () => {
  const { Title } = Typography;
  const [logo, setLogo] = useState(config.logo);
  const { user, logout } = useContext(AuthContext);
  const [permissions, setPermissions] = useState([]);
  const [company, setCompany] = useState('');
  const dashboard = [
    {
      title: "Kho",
      link: "/dashboard/warehouse",
    },
    {
      title: "Mô hình SX",
      link: "/simulator",
    },
  ];
  const listOI = [
    {
      title: "Sản xuất",
      link: "/oi/production",
      permission: "oi.production",
    },
    {
      title: "IQC",
      link: "/oi/quality-iqc",
      permission: "oi.quality_iqc",
    },
    {
      title: "PQC",
      link: "/oi/quality-pqc",
      permission: "oi.quality_pqc",
    },
    {
      title: "OQC",
      link: "/oi/quality-oqc",
      permission: "oi.quality_oqc",
    },
    {
      title: "Thiết bị",
      link: "/oi/equipment",
      permission: "oi.equipment",
    },
    {
      title: `Kho TP`,
      link: `/oi/finished-product-warehouse`,
      permission: "oi.finished_product_warehouse",
    },
    {
      title: `Kho NVL`,
      link: `/oi/material-warehouse-import`,
      permission: "oi.material_warehouse_import",
    },
  ];
  const listUI = [
    {
      title: "Dashboard",
      link: "/ui/kpi",
      permission: "ui.kpi",
    },
    {
      title: "Sản xuất",
      link: "/ui/manufacturing-history",
      permission: "ui.manufacturing_history",
    },
    {
      title: "Chất lượng",
      link: "/ui/iqc-quality",
      permission: "ui.iqc_quality",
    },
    {
      title: "Thiết bị",
      link: "/ui/equipment-performance",
      permission: "ui.equipment_performance",
    },
    {
      title: `Kho`,
      link: `/ui/finished-product-warehouse`,
      permission: "ui.finished_product_warehouse",
    },
  ];
  const getLogoFromLocalStorage = () => {
    const conf = localStorage.getItem('company_config');
    if (conf) {
      const company_name = JSON.parse(conf)?.company_name ?? null;
      if (!company) {
        setCompany(company_name);
      }
      const logoUrl = JSON.parse(conf)?.logo ?? null;
      if (logoUrl && logoUrl?.includes('logo.'))
        setLogo(`${config.apiUrl.replace('/api', '')}${logoUrl}`);
    }
  }

  useState(() => {
    getLogoFromLocalStorage();
    setPermissions([...(user?.role?.permissions?.map(({ slug }) => slug) || [])]);
  }, []);
  const permissionUI = [];
  const uiKeys = [{ title: 'Sản xuất', key: 'manufacture' }, { title: 'Chất lượng', key: 'quality' }, { title: 'Thiết bị', key: 'equipment' }, { title: 'Kho', key: 'warehouse' }, { title: 'KPI', key: 'kpi' }, { title: 'Master Data', key: 'master-data' }];
  const changePassword = () => {
    window.location.href = "/change-password";
  };
  return (
    <React.Fragment>
      <div className="auth-page-content" style={{ background: '#e3eaf0' }}>
        <Row className="justify-content-center" justify="center" style={{ margin: '20px 0' }}>
          <Col md={12} lg={12} xl={8}>
            <Card className="mt-4">
              <div className="text-center mt-2" style={{ textAlign: 'center' }}>
                <img className="mb-0 w-25" src={logo} alt="logo" style={{maxHeight: '200px', maxWidth: '280px'}} />
                <Title level={4} style={{ marginTop: '15px' }}>
                  {company}
                </Title>
              </div>
              <div className="p-2 mt-3">
                <Row gutter={[16, 16]}>
                  {dashboard.length > 0 && (
                    <Divider style={{ margin: 0 }}>DASHBOARD</Divider>
                  )}
                  {(dashboard ?? []).map((e, index) => {
                    return (
                      <Col span={12} key={index}>
                        <Link to={e.link}>
                          <Button type="primary" className="w-100" style={{ paddingInline: 0, width: '100%' }}>
                            {e.title}
                          </Button>
                        </Link>
                      </Col>
                    );
                    // }
                  })}
                </Row>
                <Row gutter={[16, 16]} className="mt-3">
                  <Divider style={{ marginBottom: '0px' }}>OI</Divider>
                  {listOI.filter(({permission: perm}) => !perm || permissions.includes(perm)).map((e, index) => {
                    return (
                      <Col span={12} key={index}>
                        <Link to={e.link}>
                          <Button type="primary" className="w-100" style={{ paddingInline: 0, width: '100%' }}>
                            {e.title}
                          </Button>
                        </Link>
                      </Col>
                    );
                  })}
                </Row>
                <Row gutter={[16, 16]} className="mt-3">
                  <Divider style={{ marginBottom: '0px' }}>UI</Divider>
                  {listUI.filter(({permission: perm}) => !perm || permissions.includes(perm)).map((e, index) => {
                    return (
                      <Col span={12} key={index}>
                        <Link to={e.link}>
                          <Button type="primary" className="w-100" style={{ paddingInline: 0, width: '100%' }}>
                            {e.title}
                          </Button>
                        </Link>
                      </Col>
                    );
                  })}
                </Row>

              </div>
              <Divider />
              <Space direction="vertical" style={{ width: "100%", marginTop: '20px' }}>
                <Button
                  icon={<LogoutOutlined />}
                  onClick={logout}
                  className="w-100"
                  style={{ width: '100%' }}
                >
                  Đăng xuất
                </Button>
                <Button
                  icon={<LockOutlined />}
                  onClick={changePassword}
                  className="w-100"
                  style={{ width: '100%' }}
                >
                  Đổi mật khẩu
                </Button>
              </Space>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
export default DashboardPage;
