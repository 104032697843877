// src/pages/UI/Manufacturing/ManufacturingHistory.js
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import PQCSidebar from './PQCSidebar';
import PQCTable from './PQCTable';
import PQCCharts from './PQCCharts';

const { Content } = Layout;

const PQC = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  useEffect(() => {
    console.log(filters);
  }, [filters])
  return (
    <Layout>
      <PQCSidebar onFilter={handleFilter} />
      <Layout>
        <Content className="site-layout-content">
          <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 5 }}>
            <PQCCharts filters={filters} />
            <PQCTable filters={filters} />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default PQC;
