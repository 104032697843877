import React, { useEffect, useRef, useState } from 'react';
import { Card, Row, Col, Form } from 'antd';
import { Column, Pie } from '@ant-design/plots';
import errorEquipmentLog from '../../../../api/errorEquipmentLog';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import OEEChart from './chart/OEEChart';
import ErrorFrequencyChart from './chart/ErrorFrequencyChart';

const EquipmentPerformanceCharts = ({ filters }) => {
    const [loadingPieChart, setLoadingPieChart] = useState(false);
    // Giả lập dữ liệu cho biểu đồ

    const [pieData, setPieData] = useState([]);

    const configColChart = {
        height: 200,
        data: [
            { day: '24/06', target: 15, result: 19 },
            { day: '25/06', target: 15, result: 18 },
            { day: '26/06', target: 15, result: 20 },
            { day: '27/06', target: 15, result: 16 },
            { day: '28/06', target: 15, result: 19 },
            { day: '29/06', target: 15, result: 19 },
            { day: '30/06', target: 15, result: 18 },
        ],
        xField: 'day',
        yField: 'result',
        yAxis: {
            min: 0,
        },
        label: {
            text: (d) => d.result + '%',
            textBaseline: 'bottom',
        },
        line: {
            yField: 'target',
            style: {
                stroke: '#FF6B3B',
                lineWidth: 2,
            },
        },
        tooltip: {
            channel: 'y', valueFormatter: (d) => {
                return d ?? "";
            }
        },
        legend: {
            position: 'top-left',
        },
    };

    const pieConfig = {
        animation: false,
        appendPadding: 10,
        height: 200,
        data: pieData,
        angleField: 'value',
        colorField: 'name',
        radius: 0.8,
        label: {
            text: (d) => `${d.name}\n ${d.value}`,
            position: 'outside',
        },
        tooltip: {
            title: 'name',
            items: [{
                name: 'Số lần',
                field: 'value'
            }]
        },
        legend: false,
    };

    //Test get data from api
    // useEffect(() => {
    //     fetchData({ ...filters });
    // }, [filters]);

    // const fetchData = async (filters) => {
    //     try {
    //         setLoadingPieChart(true);
    //         if (filters?.start_time) filters.start_time = dayjs(filters.start_time).format('YYYY-MM-DD');
    //         if (filters?.stop_time) filters.stop_time = dayjs(filters.stop_time).format('YYYY-MM-DD');
    //         const response = await errorEquipmentLog.equipmentErrorFrequency(filters);
    //         const pie = [];
    //         Object.keys(response.data).map(key => {
    //             pie.push({ name: key, value: response.data[key] })
    //         })
    //         setPieData(pie);
    //         setLoadingPieChart(false);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    return (
        <Row gutter={8} className='section-chart'>
            <Col span={7}>
                <ErrorFrequencyChart filters={filters} />
            </Col>
            <Col span={17}>
                <OEEChart filters={filters} />
            </Col>
        </Row>
    );
};

export default EquipmentPerformanceCharts;
