import { QrcodeOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, InputNumber, Layout, Modal, Row, Select, Space, Table, Tabs, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import warehouseEntryManagementApi from '../../../../api/warehouseEntryManagementApi';
import Scanner from '../../../../components/Scanner';
import lotApi from '../../../../api/lotApi';
import warehouseLocationManagementApi from '../../../../api/warehouseLocationManagementApi';
import { createWarehouseHistory, getWarehouseHistories, summaryWarehouseHistory } from '../../../../api/warehouseHistoryApi';
import finishedProductExportApi from '../../../../api/finishedProductExportApi';
import TabPane from 'antd/es/tabs/TabPane';
import config from '../../../../config/config';
import { numberFormat } from '../../../../utils/numberFormat';
import { tableHeight } from '../../components/OICalculateTableHeight';

const { Content } = Layout;
const { Option } = Select;

const OISemiFinishedProductWarehouseExport = () => {
    const navigate = useNavigate();
    const context = useOutletContext();
    const [form] = Form.useForm();
    const [selectedProcess, setSelectedProcess] = useState(2);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataLot, setDataLot] = useState([]);
    const [dataExit, setDataExit] = useState([]);
    const [finishedProductExportOptions, setFinishedProductExportOptions] = useState([]);
    const [finishedProductExportItems, setFinishedProductExportItems] = useState([]);
    const [dataLotScanner, setDataLotScanner] = useState({ // fields in QR
        warehouse_location_id: null,
        lot_id: null,
        quantity: 0,
        unit_id: null,
        unit_name: null,
    });
    const [tableDataLotScanner, setTableDataLotScanner] = useState([]);
    const [summary, setSummary] = useState({ import: 0, export: 0 });
    const [scannerKey, setScannerKey] = useState(Date.now());
    const isScanningRef = useRef(true);
    const isScanningLotRef = useRef(true);
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: config.pageSize,
        total: 0,
    });
    const tableH = useRef(450);

    const processOptions = [
        { label: 'Nhập kho', value: 1 },
        { label: 'Xuất kho', value: 2 },
    ];

    const columnsTop = [
        { title: "Tổng nhập", dataIndex: "import", key: "import", align: "center", render: (value) => numberFormat(value) },
        { title: "Tổng xuất", dataIndex: "export", key: "export", align: "center", render: (value) => numberFormat(value) }
    ];

    const columnsMiddle = [
        { title: "Mã Lot", dataIndex: "lot_id", key: "lot_id", align: "center", width: '100px' },
        { title: "Số lượng(kg)", dataIndex: "quantity", key: "quantity", align: "center", width: '100px', render: (value) => numberFormat(value) },
        { title: "Vị trí", dataIndex: "warehouse_location_id", key: "warehouse_location_id", align: "center", width: '100px' }
    ];

    const columnsLot = [
        { title: "STT", dataIndex: "stt", key: "stt", align: "center", render: (value, record, index) => index + 1 },
        { title: "Mã Lot", dataIndex: "id", key: "id", align: "center" },
        { title: "Mã sản phẩm", dataIndex: "product_id", key: "product_id", align: "center" },
        { title: "Số lượng", dataIndex: "quantity", key: "quantity", align: "center", render: (value) => numberFormat(value) },
        { title: "Đơn vị", dataIndex: "unit_id", key: "unit_id", align: "center", render: (_, record) => record?.product?.unit?.unit_name },
        { title: "Vị trí", dataIndex: "warehouse_location_id", key: "warehouse_location_id", align: "center", render: (_, record) => record?.warehouse_history?.type === 'export' && record?.warehouse_history?.warehouse_location_id },
    ];

    const columnsLotScanner = [
        { title: "Mã Pallet", dataIndex: "lot_id", key: "lot_id", align: "center" },
        { title: "Số lượng", dataIndex: "quantity", key: "quantity", align: "center", width: 150, render: (value) => numberFormat(value) },
        { title: "ĐVT", dataIndex: "unit_name", key: "unit_name", align: "center" },
        { title: "Vị trí", dataIndex: "warehouse_location_id", key: "warehouse_location_id", align: "center" }
    ];

    const columnsBottomExport = [
        {
            title: "STT",
            dataIndex: "stt",
            key: "stt",
            align: "center",
            width: '50px',
            render: (value, record, index) => index + 1,
        },
        {
            title: "Mã BTP",
            dataIndex: "product_id",
            key: "product_id",
            align: "center",
            width: '100px',
        },
        {
            title: "Tên BTP",
            dataIndex: "product_name",
            key: "product_name",
            align: "center",
            width: '120px',
            render: (_, record) => record?.product?.product_name,
        },
        {
            title: "SL cần xuất",
            dataIndex: "quantity",
            key: "quantity",
            align: "center",
            width: '120px',
            render: (value) => Number(value),
        },
    ];

    const columnsExit = [
        {
            title: "STT",
            dataIndex: "stt",
            key: "stt",
            align: "center",
            width: '50px',
            render: (value, record, index) => index + 1,
        },
        {
            title: "Mã lot",
            dataIndex: "warehousehistoryable_id",
            key: "warehousehistoryable_id",
            align: "center",
            width: '80px',
        },
        {
            title: "Mã BTP",
            dataIndex: "product_id",
            key: "product_id",
            align: "center",
            width: '120px',
            render: (_, record) => record?.lot?.product?.id
        },
        {
            title: "Tên BTP",
            dataIndex: "product_name",
            key: "product_name",
            align: "center",
            width: '120px',
            render: (_, record) => record?.lot?.product?.product_name
        },
        {
            title: "Số lượng",
            dataIndex: "quantity",
            key: "quantity",
            align: "center",
            width: '80px',
            render: (value) => Number(value),
        },
        {
            title: "Vị trí",
            dataIndex: "warehouse_location_id",
            key: "warehouse_location_id",
            align: "center",
            width: '80px',
        },
        {
            title: "Thời gian xuất",
            dataIndex: "created_at",
            key: "created_at",
            align: "center",
            width: '120px',
        },
        {
            title: "Người xuất",
            dataIndex: "created_by",
            key: "created_by",
            align: "center",
            width: '80px',
            render: (_, record) => record?.created_by?.name
        },
    ];

    const fetchLot = async (filters) => {
        try {
            setLoading(true);
            const response = await lotApi.getLots(filters); // Thay đổi endpoint theo API của bạn
            setDataLot(response.data?.data ?? []);
            response.data?.pagination && setPagination(response.data.pagination)
            setLoading(false);
        } catch (error) {
            message.error(error.message);
        }
    };

    const fetchFinishedProductExport = async () => {
        try {
            const response = await finishedProductExportApi.getFinishedProductExports();
            await setFinishedProductExportOptions((response.data.data ?? []).map((item) => {
                return { label: item?.id, value: item?.id };
            }));
        } catch (error) {
            message.error(error.message);
        }
    }

    const fetchSummary = async () => {
        try {
            const response = await summaryWarehouseHistory({ model: 'lot', is_semi: 1 });
            if (response?.success) {
                const data = response.data ?? [];
                const ex = data?.find(item => item?.type === 'export')?.sum ?? 0;
                const im = data?.find(item => item?.type === 'import')?.sum ?? 0;
                setSummary({ export: ex, import: im });
            }
        } catch (error) {
            message.error(error.message);
        }
    }

    const fetchDataExit = async (filters) => {
        try {
            setLoading(true);
            filters.model = 'lot';
            filters.is_semi = 1;
            const response = await getWarehouseHistories(filters); // Thay đổi endpoint theo API của bạn
            setDataExit(response.data.data);
            setLoading(false);
        } catch (error) {
            message.error(error.message);
        }
    };

    useEffect(() => {
        if (selectedProcess === 1) navigate("/oi/semi-finished-product-warehouse");
    }, [selectedProcess]);

    useEffect(() => {
        if (context && context.setTitle) {
            context.setTitle('QUẢN LÝ KHO BTP');
        }
    }, [context]);

    useEffect(() => {
        const { warehouse_location_id, lot_id, quantity, unit_id, unit_name } = dataLotScanner;
        setTableDataLotScanner([{
            key: 1,
            warehouse_location_id,
            lot_id,
            quantity,
            unit_id,
            unit_name
        }]);
    }, [dataLotScanner]);

    useEffect(() => {
        fetchFinishedProductExport();
        fetchSummary();
        fetchLot();
        fetchDataExit({ type: 'export' });
    }, []);

    const handleCancel = () => {
        setScannerKey(Date.now());
        setIsModalVisible(false);
        setDataLotScanner({
            warehouse_location_id: null,
            lot_id: null,
            quantity: 0,
            unit_id: null,
        })
        setTableDataLotScanner([]);
        isScanningLotRef.current = true;
    };

    const handleCreateWarehouseEntry = async () => {
        try {
            const { warehouse_location_id, lot_id, quantity, unit_id } = dataLotScanner;

            if (!lot_id) throw new Error('CHƯA CÓ THÔNG TIN MÃ LOT!');
            if (!warehouse_location_id) throw new Error('CHƯA CÓ THÔNG TIN VỊ TRÍ!');
            const response = await createWarehouseHistory({
                // finished_product_export_id: finishedProductExportSelected, // export_id
                warehouse_location_id,
                lot_id,
                quantity,
                unit_id,
                type: 'export',
            });;

            if (response?.success) {
                fetchSummary();
                fetchLot();
                fetchDataExit({ type: 'export' });
                handleCancel();
            }
        } catch (error) {
            message.error(error.message);
        }
    }

    const handleQrScanner = async (data) => {
        if (data && isScanningRef.current) {
            isScanningRef.current = false;

            setLoading(true);
            try {
                console.log(data);
                const response = await warehouseEntryManagementApi.getWarehouseEntries({ lot_id: data });
                if (response.data) {
                    const dataWarehouseEntry = response.data?.data ?? [];
                    if (!dataWarehouseEntry[0]?.id) throw new Error('KHÔNG LẤY ĐƯỢC VỊ TRÍ LOT!');
                    const { warehouse_location_id } = dataWarehouseEntry[0]; // Get quantity and location_id from WarehouseEntry
                    const result = finishedProductExportItems.find((item) => item.lot_id === data);
                    if (result?.id) { // Check lot_id
                        // Get lot
                        const resLot = await lotApi.getLotById(data);
                        if (resLot?.success) {
                            const { product, quantity } = resLot.data;
                            await setDataLotScanner(prevState => ({ ...prevState, lot_id: data, unit_id: product?.unit?.id, unit_name: product?.unit?.unit_name, quantity, warehouse_location_id }));
                        }
                    } else throw new Error('MÃ LOT KHÔNG HỢP LỆ!');
                }
            } catch (error) {
                message.error(error.message);
                setDataLotScanner(prevState => ({ ...prevState, lot_id: null }));
            } finally {
                setLoading(false);
            }

            setTimeout(() => {
                isScanningRef.current = true;
            }, 2000);
        }
    }

    const handleOkScanner = async () => {
        handleCreateWarehouseEntry();
    }

    const handleOpenLot = async () => {
        setScannerKey(Date.now());
        setIsModalVisible(true);
    }

    const titleModal = () => {
        return dataLotScanner.lot_id ? 'Quét QR Vị trí' : 'Quét QR Lot';
    }

    const handleMaterialExportSelected = async (value) => {
        console.log(value);
        const response = await finishedProductExportApi.getFinishedProductExportItems({ finished_product_export_id: value });
        if (response?.success) {
            setFinishedProductExportItems(response?.data?.data ?? []);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            let tableHCurrent = tableHeight("table-sfd-export");
            if (tableHCurrent) {
                tableH.current = tableHCurrent;
            }
        }, 300);
    }, [dataLot]);

    return (
        <Content className="oi-content">
            <Row className="mt-1" gutter={[6, 12]}>
                <Col span={24} className='section-top'>
                    <Row gutter={[8, 8]}>
                        <Col span={6} xs={8} md={6}>
                            <div className="oi-select-container" style={{ width: '100%' }}>
                                <div className="oi-select-header">Thao tác</div>
                                <Select
                                    optionFilterProp='label'
                                    options={processOptions}
                                    value={selectedProcess}
                                    style={{ width: '100%', height: '-webkit-fill-available' }}
                                    onChange={setSelectedProcess}
                                    className="oi-select"
                                    placeholder="Thao tác"
                                />
                            </div>
                        </Col>
                        <Col span={18} xs={24} md={18}>
                            <Table
                                className="custom-table"
                                dataSource={[{ ...summary }]}
                                columns={columnsTop.map(col => ({ ...col, align: 'center' }))}
                                pagination={false}
                                size="small"
                                bordered
                            />
                        </Col>
                    </Row>
                </Col>
                {/* <Col span={20} xs={24} md={20} className='section-middle'>
                    <Table
                        className="custom-table"
                        dataSource={[{ key: 1, ...dataLotScanner }]}
                        columns={columnsMiddle.map(col => ({ ...col, align: 'center' }))}
                        pagination={false}
                        size="small"
                        bordered
                    />
                </Col>
                <Col span={4} xs={24} md={4}>
                    <Button style={{ width: '100%', height: '100%', fontSize: '16px' }} type="primary" icon={<QrcodeOutlined />} onClick={handleOpenLot}>
                        Quét QR
                    </Button>
                </Col> */}
                <Col span={24}>
                    <Table
                        className='table-sfd-export'
                        loading={loading}
                        dataSource={dataExit}
                        columns={columnsExit.map(col => ({ ...col, align: 'center' }))}
                        pagination={false}
                        size="small"
                        scroll={{ x: '900px', y: tableH.current }}
                        bordered
                    />
                </Col>
            </Row>

            {/* Modal Material QR Scanner */}
            <Modal
                key={scannerKey}
                centered
                title={titleModal()}
                open={isModalVisible}
                onCancel={handleCancel}
                onOk={handleOkScanner}
                cancelText="Thoát"
                okText="Xác nhận"
                width={500}
            >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <div>
                        <Scanner key={scannerKey} isScan={true} onResult={(res) => handleQrScanner(res)} />
                    </div>

                    <Table
                        className="custom-table"
                        dataSource={tableDataLotScanner}
                        columns={columnsLotScanner}
                        pagination={false}
                        size="small"
                        bordered
                    />
                </Space>
            </Modal>
        </Content>
    );
};

export default OISemiFinishedProductWarehouseExport;
