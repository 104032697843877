// src/pages/UI/Role/RoleTable.js

import React, { useState, useEffect, useRef, useContext } from 'react';
import { Table, Button, Space, Modal, Form, Input, Card, Upload, message, Popconfirm, Select, Tag, Divider, Row, Col, Checkbox } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, PrinterOutlined, EyeFilled, EyeOutlined, CheckSquareTwoTone, CheckSquareOutlined } from '@ant-design/icons';
import axiosInstance from '../../../api/axiosInstance'; // Import axiosInstance
import './RoleTable.css';
import { useReactToPrint } from 'react-to-print';
import roleApi from "../../../api/roleManagementApi.js";
import config from '../../../config/config';
import departmentManagementApi from '../../../api/departmentManagementApi';
import permissionManagementApi from '../../../api/permissionManagementApi';
import roleManagementApi from '../../../api/roleManagementApi.js';
import AuthContext from '../../../context/AuthContext.js';

const RoleTable = ({ filters }) => {
  const { user } = useContext(AuthContext);
  const [permissions, setPermissions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [editingRecord, setEditingRecord] = useState(null);
  const [data, setData] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [permissionOptions, setPermissionOptions] = useState([]);
  const [permissionChecked, setPermissionChecked] = useState([]);
  const [uiPermissionChecked, setUIPermissionChecked] = useState([]);
  const [oiPermissionChecked, setOIPermissionChecked] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    setPagination(paginate)
    fetchDepartment();
    fetchPermission();
    fetchData({ ...filters, ...paginate });
  }, [filters]);

  useEffect(() => {
    if (htmlContent) {
      handlePrint();
    }
  }, [htmlContent]);

  useEffect(() => {
    setPermissions([...user?.role?.permissions?.map(({ slug }) => slug)]);
  }, []);

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      const response = await roleManagementApi.getRoles(filters); // Thay đổi endpoint theo API của bạn
      setData(response.data.data ?? []);
      response.data?.pagination && setPagination(response.data.pagination)
    } catch (error) {
      message.error('Failed to fetch roles');
    } finally {
      setLoading(false);
    }
  };

  const fetchDepartment = async () => {
    try {
      const response = await departmentManagementApi.getDepartments();
      await setDepartmentOptions((response.data.data ?? []).map((item) => {
        return { label: item?.name, value: item?.id };
      }));
      if ((response.data.data ?? []).length === 0) message.info('Dữ liệu phòng ban trống!')
    } catch (error) {
      message.error('Failed to fetch departments');
    }
  }

  const fetchPermission = async () => {
    try {
      const response = await permissionManagementApi.getPermissions();
      await setPermissionOptions((response.data.data ?? []).map((item) => {
        return { label: item?.slug ?? item?.id, value: item?.id, name: item?.name };
      }));
    } catch (error) {
      message.error('Failed to fetch permissions');
    }
  }

  const showModal = () => {
    setIsEditMode(false);
    setPermissionChecked([]);
    setUIPermissionChecked([]);
    setOIPermissionChecked([]);
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  const showEditModal = (record) => {
    const department_id = record?.department?.id ?? null;
    const permission_id = record?.permissions?.map((item) => { return item?.id });
    setPermissionChecked([...record?.permissions?.filter(
      ({ slug }) =>
        String(slug).endsWith(".search") ||
        String(slug).endsWith(".create") ||
        String(slug).endsWith(".update") ||
        String(slug).endsWith(".delete")
    )?.map(({ slug }) => slug)]);
    setUIPermissionChecked(
      record?.permissions.filter(({ slug }) => String(slug).startsWith("ui.")).map(item => item.id)
    );
    setOIPermissionChecked(
      record?.permissions.filter(({ slug }) => String(slug).startsWith("oi.")).map(item => item.id)
    );
    setIsEditMode(true);
    setEditingRecord(record);
    form.setFieldsValue({ ...record, department_id, permission_id });
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    const permissionIds = permissionOptions.filter((item) => permissionChecked.includes(item.label)).map(({ value }) => value);
    if (permissionIds.length > 0) {
      form.validateFields().then(async (values) => {
        try {
          if (isEditMode) {
            const response = await roleManagementApi.updateRole(editingRecord.id, {
              ...values,
              permission_id: [...permissionIds, ...uiPermissionChecked, ...oiPermissionChecked],
            }); // Thay đổi endpoint theo API của bạn
            if (response.success) {
              // setData((prevData) => [{ ...values }, ...prevData.filter(item => item.id !== editingRecord.id)]);
              await fetchData(pagination);
            } else throw new Error('Action failure');
          } else {
            const response = await roleManagementApi.createRole({ ...values, permission_id: [...permissionIds, ...uiPermissionChecked, ...oiPermissionChecked] }); // Thay đổi endpoint theo API của bạn
            if (response.success) {
              // setData((prevData) => [{ ...values, id: response.data.id }, ...prevData]);
              setPagination({ ...pagination, total: pagination.total++ });
              await fetchData(pagination);
            } else throw new Error('Action failure');
          }
          setIsModalVisible(false);
          form.resetFields();

          // Load profile
          // loadProfile();

        } catch (error) {
          message.error('Failed to save product');
        }
      }).catch((error) => {
        console.log(error);
      });
    } else message.info('VUI LÒNG CHỌN QUYỀN HẠN!');
  };

  // const loadProfile = async () => {
  //   let getUser = JSON.parse(localStorage.getItem('user') || '{}');
  //   const resProfile = await axiosInstance.get(config.apiUrl + '/profile');
  //   if (resProfile.success) {
  //     getUser = {...getUser, role: resProfile.data?.role};
  //     await localStorage.setItem('user', JSON.stringify(getUser));
  //   }
  // }

  const handleDelete = async (id) => {
    try {
      await roleManagementApi.deleteRole(id); // Thay đổi endpoint theo API của bạn
      setData((prevData) => prevData.filter(item => item.id !== id));
      // message.success('Xóa chức vụ thành công');
    } catch (error) {
      message.error('Failed to delete equipment');
    }
  };
  const printRecord = async (record) => {
    const res = roleManagementApi.getRoleById(record.id);
    if (res.success) {
      setHtmlContent(res.data);
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const handleUploadChange = (info) => {
    try {
      if (info.file.status === 'uploading') {
        setUploading(true);
      } else {
        if (info.file.status === 'done') {
          if (!info.file?.response?.success)
            throw new Error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`)
          fetchData({ ...filters, ...pagination });
          message.success(`${info.file.name} - Upload thành công`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} - Upload thất bại`);
        }
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setUploading(false);
    }
  };

  const uploadProps = {
    name: 'file',
    accept: '.xlsx, .xls',
    action: config.apiUrl + '/import-roles', // Đường dẫn API để xử lý upload file
    onChange: handleUploadChange,
    showUploadList: false, // Ẩn danh sách file
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
    },
    {
      title: 'Mã chức vụ',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'Tên chức vụ',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Phòng ban',
      dataIndex: 'department_name',
      key: 'department_name',
      align: 'center',
      render: (_, record) => record?.department?.name,
    },
    {
      title: 'Quyền hạn',
      dataIndex: 'permission_name',
      key: 'permission_name',
      align: 'center',
      render: (_, record) => {
        // return record?.permissions?.map((item) => (<Tag color="#2db7f5">{item?.name}</Tag>))
        return record?.permissions?.length;
      },
    },
    {
      title: 'Hành động',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {permissions.includes('role.update') && (<Button type='primary' size='small' icon={<EditOutlined />} onClick={() => showEditModal(record)} />)}
          {permissions.includes('role.delete') && (<Popconfirm
            title="Bạn có chắc chắn muốn xóa?"
            onConfirm={() => handleDelete(record.id)}
            okText="Có"
            cancelText="Không"
          >
            <Button type='primary' size='small' icon={<DeleteOutlined />} danger />
          </Popconfirm>)}
        </Space>
      ),
      align: 'center',
    },
  ];

  const getSlugParent = () => {
    return [...new Set(permissionOptions.filter(
      ({ label }) =>
        String(label).endsWith(".search") ||
        String(label).endsWith(".create") ||
        String(label).endsWith(".update") ||
        String(label).endsWith(".delete")
    ).map((item) => String(item.label).split('.')[0]))];
  }

  const handleSlugParentClick = (value) => {
    const perms = permissionOptions.filter((item) => String(item.label).startsWith(`${value}.`));
    const permChecked = perms.filter(({ label: v }) => {
      if (permissionChecked.includes(v)) return true;
      return false;
    });
    if (permChecked.length === perms.length) {
      let newPerm = permissionChecked.filter((val) => {
        if (perms.filter((item) => item.label === val).length > 0) return false;
        return true;
      });
      setPermissionChecked([...newPerm]);
    } else if (permChecked.length > 0) {
      let newPerm = perms.filter((item) => !permissionChecked.includes(item.label));
      setPermissionChecked((prev) => [...prev, ...(newPerm.map((item) => item.label))]);
    } else {
      setPermissionChecked((prev) => [...prev, ...(perms.map((item) => item.label))]);
    }
  }

  const handleCheckAll = () => {
    if (permissionChecked.length > 0) setPermissionChecked([]);
    else setPermissionChecked(permissionOptions.filter(
      ({ label }) =>
        String(label).endsWith(".search") ||
        String(label).endsWith(".create") ||
        String(label).endsWith(".update") ||
        String(label).endsWith(".delete")
    ).map((item) => item.label));
  }

  const handleCheckAllUI = () => {
    if (uiPermissionChecked.length > 0) setUIPermissionChecked([]);
    else setUIPermissionChecked(permissionOptions.filter(({ label }) => String(label).startsWith('ui.')).map(item => item.value));
  };

  const handleCheckAllOI = () => {
    if (oiPermissionChecked.length > 0) setOIPermissionChecked([]);
    else setOIPermissionChecked(permissionOptions.filter(({ label }) => String(label).startsWith('oi.')).map(item => item.value));
  };

  const handleColChecked = (action) => {
    let ckSlug = permissionChecked.filter((val) => String(val).endsWith(`.${action}`));
    if (ckSlug.length > 0) setPermissionChecked((prev) => [...(prev.filter((val) => !ckSlug.includes(val)))]);
    else setPermissionChecked((prev) => [...prev, ...(permissionOptions.filter(({ label: val }) => String(val).endsWith(`.${action}`))).map(({ label }) => label)]);
  }

  const translateSlug = (value) => {
    switch (value) {
      case 'bom':
        return 'BOM';
      case 'customer':
        return 'Khách hàng';
      case 'department':
        return 'Phòng ban';
      case 'equipment':
        return 'Thiết bị';
      case 'equipment_parameter':
        return 'Thông số thiết bị';
      case 'error_equipment':
        return 'Lỗi dừng máy';
      case 'error_quality':
        return 'Lỗi công đoạn';
      case 'finished_product_export':
        return 'Xuất thành phẩm';
      case 'finished_product_import':
        return 'Nhập thành phẩm';
      case 'material_export':
        return 'Xuất NVL';
      case 'material_receipt':
        return 'Nhập NVL';
      case 'material':
        return 'Nguyên vật liệu';
      case 'process':
        return 'Công đoạn';
      case 'production_plan':
        return 'Kế hoạch sản xuất';
      case 'product':
        return 'Thành phẩm';
      case 'standard_parameter':
        return 'Thông số tiêu chuẩn';
      case 'supplier':
        return 'Nhà cung cấp';
      case 'warehouse':
        return 'Kho';
      case 'warehouse_location':
        return 'Vị trí kho';
      case 'role':
        return 'Chức vụ';
      case 'permission':
        return 'Quyền hạn';
      case 'user':
        return 'Người dùng';
      case 'template':
        return 'Template';
      case 'engine_power':
        return 'Năng suất máy';
      case 'unit':
        return 'Đơn vị tính';
      case 'inspection_criteria':
        return 'Chỉ tiêu';
      default:
        return 'Not found';
    }
  }

  return (
    <Card
      title="Quản lý chức vụ"
      extra={
        <div className="table-actions">
          {/* <Button type="primary">Xuất file</Button> */}
          {/* <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />} type="primary" loading={uploading} style={{ margin: '0 8px' }}>
              {uploading ? 'Đang tải lên' : 'Upload Excel'}
            </Button>
          </Upload> */}
          <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>Thêm chức vụ</Button>
        </div>
      }
    >
      <Table
        loading={loading}
        // rowSelection={rowSelection}
        columns={columns}
        dataSource={data.map((record, key) => { return { key, ...record } })}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: (page, pageSize) => {
            setPagination({ ...pagination, page, pageSize });
            fetchData({ ...filters, page, pageSize });
          }
        }}
        bordered  // Thêm thuộc tính bordered để có viền
        size="small"  // Sử dụng kích thước bảng nhỏ
      />
      <div ref={componentRef} dangerouslySetInnerHTML={{ __html: htmlContent }} />
      <Modal
        title={
          <div className="modal-title">
            {isEditMode ? "Chỉnh sửa chức vụ" : "Thêm chức vụ"}
            <div className="modal-title-divider"></div>
          </div>
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Lưu lại"
        cancelText="Thoát"
        width={800}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <div className="modal-form-grid">
            <Form.Item name="name" label="Tên chức vụ" rules={[{ required: true, message: 'Vui lòng nhập tên chức vụ!' }]}>
              <Input placeholder="Nhập tên chức vụ" />
            </Form.Item>
            <Form.Item name="department_id" label="Tên phòng ban" rules={[{ required: true, message: 'Vui lòng chọn tên phòng ban!' }]}>
              <Select showSearch allowClear placeholder="Chọn phòng ban" options={departmentOptions} optionFilterProp='label' />
            </Form.Item>
          </div>
          {/* <Form.Item name="permission_id" label="Tên quyền hạn" rules={[{ required: true, message: 'Vui lòng chọn tên quyền hạn!' }]}>
            <Select mode="multiple" showSearch allowClear placeholder="Chọn quyền hạn" options={permissionOptions} optionFilterProp='label' />
          </Form.Item> */}
        </Form>

        <Divider>UI</Divider>
        <Row>
          <Col className="content-item col-content-item" span={8}><b onClick={handleCheckAll}><CheckSquareOutlined /> Quyền hạn</b><small>(Đã chọn: {permissionChecked.length})</small></Col>
          <Col className="col-content-item" span={4}><b onClick={() => handleColChecked('search')}>Xem</b></Col>
          <Col className="col-content-item" span={4}><b onClick={() => handleColChecked('create')}>Thêm</b></Col>
          <Col className="col-content-item" span={4}><b onClick={() => handleColChecked('update')}>Sửa</b></Col>
          <Col className="col-content-item" span={4}><b onClick={() => handleColChecked('delete')}>Xóa</b></Col>
        </Row>
        <Row className="content-scroll" gutter={8}>
          <Col span={24}>
            <Checkbox.Group value={permissionChecked} onChange={setPermissionChecked} style={{ width: '100%' }}>
              <Row>
                <Col span={24}>
                  {getSlugParent().map((item, index) => (
                    <Row key={index}>
                      <Col className="content-item col-content-item" span={8}>
                        <div onClick={() => handleSlugParentClick(item)}>{translateSlug(item)}</div>
                      </Col>
                      <Col className="col-content-item" span={4}><Checkbox value={`${item}.search`}><EyeOutlined /></Checkbox></Col>
                      <Col className="col-content-item" span={4}><Checkbox value={`${item}.create`}><PlusOutlined /></Checkbox></Col>
                      <Col className="col-content-item" span={4}><Checkbox value={`${item}.update`}><EditOutlined /></Checkbox></Col>
                      <Col className="col-content-item" span={4}><Checkbox value={`${item}.delete`}><DeleteOutlined /></Checkbox></Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            </Checkbox.Group>
          </Col>
        </Row>

        <Divider style={{ marginBottom: '1px' }}>UI</Divider>
        <Button size="small" onClick={handleCheckAllUI}><CheckSquareOutlined /> Chọn tất cả</Button>
        <Checkbox.Group value={uiPermissionChecked} onChange={setUIPermissionChecked} style={{ width: "100%" }}>
          <Row className="content-scroll">
            {permissionOptions.filter(({ label }) => String(label).startsWith('ui.')).map((item) => (<Col key={item.value} span={6}>
              <Checkbox value={item.value}>{item.name}</Checkbox>
            </Col>))}
          </Row>
        </Checkbox.Group>

        <Divider style={{ marginBottom: '1px' }}>OI</Divider>
        <Button size="small" onClick={handleCheckAllOI}><CheckSquareOutlined /> Chọn tất cả</Button>
        <Checkbox.Group value={oiPermissionChecked} onChange={setOIPermissionChecked} style={{ width: "100%" }}>
          <Row className="content-scroll">
            {permissionOptions.filter(({ label }) => String(label).startsWith('oi.')).map((item) => (<Col key={item.value} span={6}>
              <Checkbox value={item.value}>{item.name}</Checkbox>
            </Col>))}
          </Row>
        </Checkbox.Group>
      </Modal>
    </Card>
  );
};

export default RoleTable;
