// src/pages/UI/Manufacturing/ManufacturingSidebar.js
import React, { useRef, useState } from 'react';
import { Layout, Tree, DatePicker, Button, Form, Divider, Select, ConfigProvider, Radio, Input } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import UISidebar from '../../components/Sidebar';
import axiosInstance from '../../../../api/axiosInstance';
import config from '../../../../config/config';

dayjs.locale('vi');

const SemiFinishedProductWarehouseSidebar = ({ onFilter }) => {
  const [lotOptions, setLotOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const timer = useRef();

  const formFields = [
    {
      type: 'Select',
      label: 'Loại',
      name: 'type',
      placeholder: 'Nhập/Xuất',
      options: [
        { label: 'Nhập kho', value: 'import' },
        { label: 'Xuất kho', value: 'export' },
      ],
    },
    {
      type: 'DatePicker',
      label: 'Khoảng thời gian',
      name: 'start_date',
      placeholder: 'Ngày bắt đầu',
      className: 'mb-1'
    },
    {
      type: 'DatePicker',
      name: 'end_date',
      placeholder: 'Ngày kết thúc',
    },
    // {
    //   type: 'Input',
    //   label: 'Mã Lot',
    //   name: 'lot_id',
    //   placeholder: 'Nhập mã Lot',
    // },
    {
      type: 'AutoComplete',
      label: 'Vị trí',
      name: 'warehouse_location_id',
      placeholder: 'Vị trí',
      options: locationOptions,
      search: (value) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          fetchLocation({ id: value, page: 1, pageSize: 10 });
        }, 500);
      }
    },
    {
      type: 'AutoComplete',
      label: 'Mã lot',
      name: 'lot_id',
      placeholder: 'Mã lot',
      options: lotOptions,
      search: (value) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          fetchLot({ id: value, page: 1, pageSize: 10 });
        }, 500);
      }
    },
    {
      type: 'AutoComplete',
      label: 'Mã nội bộ',
      name: 'product_id',
      placeholder: 'Mã nội bộ',
      options: productOptions,
      search: (value) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          fetchProduct({ id: value, page: 1, pageSize: 10 });
        }, 500);
      }
    },
    // {
    //   type: 'Input',
    //   label: 'Tên sản phẩm',
    //   name: 'product_name',
    //   placeholder: 'Nhập tên sản phẩm',
    // },
    // Add other fields here
  ];

  const fetchLot = async (params) => {
    const response = await axiosInstance.get(config.apiUrl + '/lots', { params });
    if (response?.success) {
      setLotOptions((response.data?.data ?? []).map((item) => ({ label: item?.id, value: item?.id })));
    }
  }

  const fetchProduct = async (params) => {
    const response = await axiosInstance.get(config.apiUrl + '/products', { params });
    if (response?.success) {
      setProductOptions((response.data?.data ?? []).map((item) => ({ value: item?.id, label: <><span>{item?.id}</span><small style={{color: 'gray'}}>({item?.product_name})</small></> })));
    }
  }

  const fetchLocation = async (params) => {
    const response = await axiosInstance.get(config.apiUrl + '/warehouse-locations', { params });
    if (response?.success) {
      setLocationOptions((response.data?.data ?? []).map((item) => ({ label: item?.id, value: item?.id })));
    }
  }

  return (
    <UISidebar formFields={formFields} onFilter={onFilter} />
  );
};

export default SemiFinishedProductWarehouseSidebar;
