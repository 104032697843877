// src/pages/UI/components/Header.js
import React, { useContext, useEffect, useState } from 'react';
import { Layout, Menu, Dropdown, Button, Avatar, Badge, List, theme } from 'antd';
import { UserOutlined, LogoutOutlined, DownOutlined, CloseOutlined, BellOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../../assets/img/logo.png';
import './Header.css';
import AuthContext from '../../../context/AuthContext';
import config from '../../../config/config';
import VirtualList from 'rc-virtual-list';

const { useToken } = theme;
const { Header } = Layout;

const UIHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useContext(AuthContext);
  const [logo, setLogo] = useState(config.logo);
  const [permissions, setPermissions] = useState([]);
  const handleExit = () => {
    navigate('/');
  };
  const userMenu = [
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: 'Đăng xuất',
    },
  ];
  const handleMenuClick = (e) => {
    console.log(e);
    switch (e.key) {
      case 'profile':

        break;
      case 'logout':
        logout()
        break;
      default:
        break;
    }
  };

  const getLogoFromLocalStorage = () => {
    const conf = localStorage.getItem('company_config');
    if (conf) {
      const logoUrl = JSON.parse(conf)?.logo ?? null;
      if (logoUrl && logoUrl?.includes('logo.'))
        setLogo(`${config.apiUrl.replace('/api', '')}${logoUrl}`);
    }
  }

  const loadPermission = () => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (user?.role?.permissions) {
      setPermissions([...user.role.permissions?.map(({slug}) => slug)]);
    }
  }

  useEffect(() => {
    getLogoFromLocalStorage();
    loadPermission();
  }, []);
  const items = [
    {
      label: 'Thông báo 1',
      content: 'Đã xảy ra sự cố máy',
      read_at: '2024-06-17T09:55:00',
      key: '1',
    },
    {
      label: 'Thông báo 2',
      content: 'Đã cập nhật KHSX',
      key: '2',
    },
    {
      label: 'Thông báo 3',
      content: 'Thông báo được gửi từ nhân viên ABC',
      read_at: '2024-06-17T09:55:00',
      key: '3',
    },
    {
      label: 'Thông báo 4',
      content: 'Không có gì để thông báo',
      read_at: '2024-06-17T09:55:00',
      key: '4',
    },
    {
      label: 'Thông báo 5',
      content: 'Thông báo',
      key: '5',
    },
  ];

  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const customBorder = (index, items = []) => {
    if (index === 0) {
      return { borderTopLeftRadius: 8, borderTopRightRadius: 8 }
    }
    else if (index === (items.length - 1)) {
      return { borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }
    }
  }
  const [notificationData, setNotificationData] = useState([]);
  const ContainerHeight = 250;
  // const fakeDataUrl =
  //   'https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo';
  // const appendData = () => {
  //   fetch(fakeDataUrl)
  //     .then((res) => res.json())
  //     .then((body) => {
  //       setNotificationData(notificationData.concat(body.results));
  //     })
  //     .catch((error) => console.log(error.message));
  // };
  // useEffect(() => {
  //   appendData();
  // }, []);
  const onScroll = (e) => {
    if (Math.abs(e.currentTarget.scrollHeight - e.currentTarget.scrollTop - ContainerHeight) <= 1) {
      // appendData();
    }
  };
  const onClickNotification = (item) => {
    console.log(item);
  }
  const menuItems = [
    {
      key: 'sub0',
      title: 'Dashboard',
      link: '/ui/kpi',
      subItems: [],
    },
    {
      key: 'sub1',
      title: 'Sản xuất',
      subItems: [
        {
          key: '1-0',
          title: 'Kế hoạch sản xuất',
          link: '/ui/production-plan',
        },
        {
          key: '1-1',
          title: 'Quản lý lịch sử sản xuất',
          link: '/ui/manufacturing-history',
        },
        {
          key: '1-2',
          title: 'Quản lý CT, TT',
          link: '/ui/manufacturing-history/cycle-time',
        },
      ],
    },
    {
      key: 'sub2',
      title: 'Chất lượng',
      subItems: [
        {
          key: '2-0',
          title: 'IQC',
          link: '/ui/iqc-quality',
        },
        {
          key: '2-1',
          title: 'PQC',
          link: '/ui/pqc-quality',
        },
        {
          key: '2-2',
          title: 'OQC',
          link: '/ui/oqc-quality',
        },
      ],
    },
    {
      key: 'sub3',
      title: 'Thiết bị',
      subItems: [
        {
          key: '3-1',
          title: 'Hiệu suất thiết bị',
          link: '/ui/equipment-performance',
        },
        {
          key: '3-2',
          title: 'Thông số thiết bị',
          link: '/ui/equipment-parameters',
        },
        {
          key: '3-3',
          title: 'Kế hoạch bảo dưỡng thiết bị',
          link: '/ui/equipment-maintenance-plan',
        },
        {
          key: '3-4',
          title: 'Lịch sử bảo dưỡng thiết bị',
          link: '/ui/equipment-maintenance-history',
        },
      ],
    },
    {
      key: 'sub5',
      title: 'Kho',
      subItems: [
        {
          key: '5-1',
          title: 'Kho thành phẩm',
          link: '/ui/finished-product-warehouse',
        },
        {
          key: '5-2',
          title: 'Kho nguyên vật liệu',
          link: '/ui/material-warehouse',
        },
        {
          key: '5-7',
          title: 'Kho bán thành phẩm',
          link: '/ui/semi-finished-product-warehouse',
        },
        {
          key: '5-3',
          title: 'Kho tiêu hao',
          link: '/ui/sparepart-warehouse',
        },
        {
          key: '5-4',
          title: 'Phiếu nhập kho NVL',
          link: '/ui/material-receipt-management',
        },
        {
          key: '5-5',
          title: 'Phiếu xuất kho NVL',
          link: '/ui/material-export-management',
        },
        // {
        //   key: '5-6',
        //   title: 'Phiếu nhập kho TP',
        //   link: '/ui/finished-product-import-management',
        // },
        {
          key: '5-6',
          title: 'Phiếu xuất kho TP',
          link: '/ui/finished-product-export-management',
        },
      ],
    },
    {
      key: 'sub4',
      title: 'Master data',
      subItems: [
        { key: '4-1', title: 'Quản lý mã nội bộ', link: '/ui/product-management', auth: 'product.search' },
        { key: '4-2', title: 'Quản lý nguyên vật liệu', link: '/ui/material-management', auth: 'material.search' },
        { key: '4-2-1', title: 'Quản lý nhà cung cấp', link: '/ui/supplier-management', auth: 'supplier.search' },
        { key: '4-3', title: 'Quản lý tài khoản', link: '/ui/account-management', auth: 'user.search' },
        { key: '4-4', title: 'Quản lý template', link: '/ui/template-management', auth: 'template.search' },
        { key: '4-5', title: 'Quản lý thiết bị', link: '/ui/equipment-management', auth: 'equipment.search' },
        { key: '4-6', title: 'Quản lý năng suất máy', link: '/ui/engine-power-management', auth: 'engine_power.search' },
        { key: '4-7', title: 'Quản lý kho', link: '/ui/warehouse-management', auth: 'warehouse.search' },
        { key: '4-8', title: 'Quản lý vị trí kho', link: '/ui/warehouse-location-management', auth: 'warehouse_location.search' },
        { key: '4-9', title: 'Quản lý công đoạn', link: '/ui/process-management', auth: 'process.search' },
        { key: '4-10', title: 'Quản lý đơn vị tính', link: '/ui/unit-management', auth: 'unit.search' },
        { key: '4-11', title: 'Quản lý lỗi công đoạn', link: '/ui/error-quality-management', auth: 'error_quality.search' },
        { key: '4-12', title: 'Quản lý bán thành phẩm', link: '/ui/semi-finished-products-management', auth: '' },
        { key: '4-13', title: 'Quản lý lỗi dừng máy', link: '/ui/error-equipment-management', auth: 'error_equipment.search' },
        { key: '4-14', title: 'Quản lý thông số thiết bị', link: '/ui/equipment-parameter-management', auth: 'equipment_parameter.search' },
        { key: '4-15', title: 'Quản lý khách hàng', link: '/ui/customer-management', auth: 'customer.search' },
        { key: '4-16', title: 'Quản lý thông số tiêu chuẩn', link: '/ui/standard-parameter-management', auth: 'standard_parameter.search' },
        { key: '4-18', title: 'Quản lý BOM', link: '/ui/bom-management', auth: 'bom.search' },
        { key: '4-19', title: 'Quản lý chỉ tiêu', link: '/ui/inspection-criteria-management', auth: 'inspection_criteria.search' },
        { key: '4-20', title: 'Quản lý phòng ban', link: '/ui/department-management', auth: 'department.search' },
        { key: '4-21', title: 'Quản lý chức vụ', link: '/ui/role-management', auth: 'role.search' },
        { key: '4-22', title: 'Quản lý quyền hạn', link: '/ui/permission-management', auth: 'permission.search' },
      ],
    },
  ];

  const noficationRender = ({ props }) => {
    return (
      <List style={contentStyle}>
        <VirtualList
          data={props.items}
          itemHeight={40}
          itemKey="key"
          height={ContainerHeight}
          style={{ width: 300 }}
          onScroll={onScroll}
        >
          {(item, index) => (
            <List.Item key={item.key} style={{ background: item.read_at ? '#ced4d973' : 'white', ...customBorder(index, props.items) }} onClick={() => onClickNotification(item)}>
              <List.Item.Meta
                avatar={<Avatar style={{
                  backgroundColor: Math.floor(Math.random() * 16777215).toString(16),
                }}>TB</Avatar>}
                title={<a href="#">{"Thông báo " + (index + 1)}</a>}
                description={item.email}
                style={{ marginInline: 8, opacity: item.read_at ? 0.5 : 1 }}
              />
            </List.Item>
          )}
        </VirtualList>
      </List>
    );
  }

  const getSelectedKeys = (pathname) => {
    for (const menuItem of menuItems) {
      if (menuItem.link === pathname) {
        return [menuItem.key];
      }
      for (const subItem of menuItem.subItems) {
        if (subItem.link === pathname) {
          return [menuItem.key, subItem.key];
        }
      }
    }
    return [];
  };
  return (
    <Header className="header header-ui">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <Menu mode="horizontal" selectedKeys={getSelectedKeys(location.pathname)} className="menu" style={{ minWidth: 0, flex: "auto" }}>
        {menuItems.filter((item) => {
          if (item.subItems.length === 0) return true;
          else {
            if (item.subItems.filter(({ auth }) => !auth || permissions.includes(auth)).length > 0) return true;
            else return false;
          }
        }).map((menuItem) =>
          menuItem.subItems.filter(({auth}) => !auth || permissions.includes(auth)).length > 0 ? (
            <Menu.SubMenu key={menuItem.key} title={menuItem.title}>
              {menuItem.subItems.filter(({auth}) => !auth || permissions.includes(auth)).map((subItem) => (
                <Menu.Item key={subItem.key}>
                  <Link to={subItem.link}>{subItem.title}</Link>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ) : (
            <Menu.Item key={menuItem.key}>
              <Link to={menuItem.link}>{menuItem.title}</Link>
            </Menu.Item>
          )
        )}
      </Menu>
      <div className="user-section">
        {/* <Dropdown menu={{ items: notificationData }} trigger={['click']} placement='bottomRight' dropdownRender={noficationRender} overlayStyle={{ contentStyle }}>
          <Badge count={items.length} size='small'>
            <BellOutlined style={{ fontSize: 25, color: ' #fff', cursor: 'pointer' }} />
          </Badge>
        </Dropdown> */}
        {/* <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} className="user-menu" /> */}
        <Dropdown menu={{ items: userMenu, onClick: handleMenuClick, }} className="user-menu">
          <Button>
            {user.name} <DownOutlined />
          </Button>
        </Dropdown>
        <Button icon={<CloseOutlined />} onClick={handleExit} style={{ marginLeft: '16px' }} />
      </div>
    </Header>
  );
};

export default UIHeader;
